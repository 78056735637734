/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  FormControl,
  FormHelperText,
  TextField,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  InputLabel,
  Typography,
  useMediaQuery,
  createTheme,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import {
  updateUserCall,
  updateUserSuccess,
  getUserInfo,
  createUserCall,
} from "../../service/account_service";
import accountActions from "../../redux/actions/accountActions";
import {
  formErrorHandling,
  formFieldsConfig,
  stateFieldConfig,
} from "../../shared/util/UserValidations";
import {
  stateList,
  birthMonth,
  birthDay,
  birthYear,
  country,
} from "../../utils/state";

function FormWrapper(props) {
  const { retailerName, fields } = props;
  const usernameValue= fields.filter((item)=>item.name=='username')
  const { user } = useSelector((state) => state);
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const retailerFormFields = formFieldsConfig(props.fields, props.formType);
  const [isLoading, setLoading] = useState(false);

  const errorIcon = <ErrorOutlineOutlinedIcon style={{ fill: "#ff0000" }} />;
  const dropdownIcon = <ArrowDropDownOutlinedIcon />;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState(
    stateFieldConfig(retailerFormFields, "", props.formType)
  );
  const [errors, setFormErrors] = useState(
    stateFieldConfig(retailerFormFields, "error")
  );
  const stateData = useState(stateList);
  const [formError, setFormError] = useState("");
  const [successMsg, setSuccessMsg] = useState(null);
  const [profileUpdErrMsg, setProfileUpdErrMsg] = useState(null);

  const useStyles = makeStyles((theme) => ({
    margin: {
      display: "flex",
      margin: theme.spacing(1),
      marginLeft: "25px",
      fontSize: "16px",
    },
    marginMobile: {
      display: "flex",
      margin: theme.spacing(1),
      marginLeft: "12px",
      fontSize: "16px",
    },
    textField: {
      fontSize: "16px",
      "&>div": {
        fontSize: "16px",
        height: "48px",
      },
      "& input": {
        padding: "14.5px 14px",
        WebkitBoxShadow: "0 0 0 1000px #fff inset",
      },
    },

    signInbtn: {
      color: "white",
      outline: "none",
      padding: "1.2rem 1.6rem",
      fontSize: "16px",
      width: props.formType === "profile" ? "190px" : "94%",
      borderRadius: "25px",
      boxShadow: "none",
      fontWeight: "bold",
      lineHeight: 1,
      backgroundColor: props.config.primary_color,
      textTransform: "capitalize",
      marginTop: "14px",
      marginLeft: "17px",
      "&:hover": {
        boxShadow: "none",
        backgroundColor: props.config.primary_color,
      },
      "&:focus": {
        boxShadow: "none",
        backgroundColor: props.config.primary_color,
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },
    },
    signInbtnMobile: {
      color: "white",
      outline: "none",
      padding: "1.2rem 1.6rem",
      fontSize: "16px",
      width: "100%",
      borderRadius: "25px",
      boxShadow: "none",
      fontWeight: "bold",
      lineHeight: 1,
      backgroundColor: props.config.primary_color,
      textTransform: "capitalize",
      marginTop: "14px",
      marginLeft: "17px",
      "&:hover": {
        boxShadow: "none",
        backgroundColor: props.config.primary_color,
      },
      "&:focus": {
        boxShadow: "none",
        backgroundColor: props.config.primary_color,
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },
    },
    updateProfileBtn: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      flexWrap: "wrap",
    },
    loyaltyId: {
      fontSize: "14px",
      color: "#212931",
      fontWeight: "700",
      marginLeft: 0,

      // Mobile css
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
        "& .MuiInputBase-input": {
          fontSize: "14px",
        },
      },
    },
    mandatoryField: {
      color: "red",
    },
    errorMessage: {
      fontSize: "14px",
      fontWeight: "400",
      marginLeft: "28px",
      color: "#D82B0C",
      fontStyle: "normal",
      textTransform: "capitalize",
    },
    errorMobile: {
      fontSize: "14px",
      fontWeight: "400",
      marginLeft: "12px",
      color: "#D82B0C",
      fontStyle: "normal",
    },
    errortextfield: {
      borderRadius: "4px",
      background: "#FBEAE7",
      fontSize: "16px",
      "&>div": {
        fontSize: "16px",
        height: "48px",
      },
      "& input": {
        padding: "14.5px 14px",
        WebkitBoxShadow: "0 0 0 1000px #FBEAE7 inset",
      },
    },

    selectBox: {
      padding: "10px 0",
      fontSize: "16px",
      textAlign: "left",
      "&> :first-child": {
        padding: "4.5px 11px !important",
        fontSize: "16px",
        textAlign: "left",
        minWidth: "92%",
        "&:focus": {
          backgroundColor: "#fff",
        },
      },
    },
    selectBoxerror: {
      padding: "10px 14px",
      fontSize: "16px",
      textAlign: "left",
      borderRadius: "4px",
      background: "#FBEAE7",
      "&> :first-child": {
        padding: "4.5px 0 !important",
        fontSize: "16px",
        textAlign: "left",
        minWidth: "100%",
        "&:focus": {
          backgroundColor: "#FBEAE7",
        },
      },
    },
    selectValueBox: {
      fontSize: "16px",
      padding: "10px 14px",
      textAlign: "left",
      "&> :first-child": {
        padding: "4.5px 0",
        fontSize: "16px",
        textAlign: "left",
        minWidth: "100%",
        "&:focus": {
          backgroundColor: "#fff",
        },
      },
    },
    selectPlaceholder: {
      textAlign: "left",
      fontSize: "16px",
      color: "#54687C !important",
      fontStyle: "normal",
      fontWeight: "400",
      marginTop: "31px",
      opacity: "0.5",
      transform: "translate(14px, 20px) scale(1) !important",
    },
    selectMobilePlaceholder: {
      textAlign: "left",
      fontSize: "16px",
      color: "#54687C !important",
      fontStyle: "normal",
      fontWeight: "400",
      marginTop: "28px",
      opacity: "0.5",
      transform: "translate(14px, 20px) scale(1) !important",
    },
    selectIcon: {
      position: "absolute",
      right: "14px",
      cursor: "pointer",
      pointerEvents: "none",
    },
    selectValueicon: {
      position: "absolute",
      right: "14px",
      cursor: "pointer",
      pointerEvents: "none",
    },
    checkAlign: {
      textAlign: "left",
      paddingLeft: "5%",
      marginTop: "12px",
    },
    checkAlignMobile: {
      textAlign: "left",
      paddingLeft: "14px",
      marginTop: "12px",
    },
    alertBoxerror: {
      display: "flex",
      alignItems: "center",
      padding: "1.4rem 1.6rem",
      borderRadius: "8px",
      color: " #561104",
      background: "#FBEAE7",
      border: "1px solid #D82B0C",
      fontSize: "16px",
      width: "94%",
      marginTop: "14px",
      marginLeft: "24px",
    },
    alertBoxsucesserror: {
      display: "flex",
      alignItems: "center",
      padding: "1.4rem 1.6rem",
      borderRadius: "8px",
      color: " #44a047",
      background: "#5d9d5133",
      border: "1px solid #44a047",
      fontSize: "16px",
      width: "94%",
      marginTop: "14px",
      marginLeft: "24px",
    },
    alertBoxsucessMobile: {
      display: "flex",
      alignItems: "center",
      padding: "1.4rem 1.6rem",
      borderRadius: "8px",
      color: " #44a047",
      background: "#5d9d5133",
      border: "1px solid #44a047",
      fontSize: "16px",
      width: "96%",
      marginLeft: "9px",
      marginTop: "10px",
    },
    alertBoxMobile: {
      display: "flex",
      alignItems: "center",
      padding: "1.4rem 1.6rem",
      borderRadius: "8px",
      color: " #561104",
      background: "#FBEAE7",
      border: "1px solid #D82B0C",
      fontSize: "16px",
      width: "96%",
      marginLeft: "9px",
      marginTop: "10px",
    },
    storeBlock: {
      display: "flex",
      flexDirection: "column",
    },
    storeTitle: {
      textOverflow: "ellipsis",
      paddingTop: "1px",
    },
    comboOptions: {
      fontSize: "14px",
      color: "red",
    },
    autoComplete: {
      fontSize: "16px",
    },
    option: {
      fontSize: "16px",
    },
    noOptions: {
      fontSize: "16px",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    // Set user values to profile page
    // Take cache user form cache to reduce delay.
    if (props.formType === "profile") {
      setLoading(true);
      const cachedUser = JSON.parse(localStorage.getItem("userData"))
        ? JSON.parse(localStorage.getItem("userData"))
        : user;
      setFormValues(
        stateFieldConfig(retailerFormFields, "", props.formType, cachedUser)
      );
      setLoading(false);
    }
  }, [user]);

  // Input filed values assigning
  const handleChange = (prop, type) => (event) => {
    setSuccessMsg(null);
    setProfileUpdErrMsg(null);
    const { value } = event.target;
    let typeCheck = false;
    let lengthCheck = false;
    if (prop === "phone") {
      typeCheck = isNaN(value);
      lengthCheck = 10 < value.length;
    } else if (prop === "phone") {
      typeCheck = isNaN(value);
      lengthCheck = 5 < value.length;
    }

    if (!typeCheck && !lengthCheck)
      setFormValues({ ...formValues, [prop]: value });
    setFormErrors({ ...errors, [prop]: "" });
  };

  // Drop down  values Changing
  const handleSelectChange = (prop, community) => (event) => {
    const value = event.target.value;
    setFormValues({
      ...formValues,
      [prop]: community ? event.target.getAttribute("name") : value,
    });
    setFormErrors({ ...errors, [prop]: "" });
    setSuccessMsg(null);
    setProfileUpdErrMsg(null);
  };

  // Create Account Click functionality
  const handleCreate = async () => {
    setSuccessMsg(null);
    setFormErrors(null);

    let formerrors = errors;
    retailerFormFields.map((data) => {
      const validationCheck =
        (data.isRequired || data.required) && !data.disabled;
      if (validationCheck && formValues[data.name] === "") {
        formerrors = {
          ...formerrors,
          [data.name]: `${data.label} is required`,
        };
      } else if (formValues[data.name] !== "") {
        formerrors = {
          ...formerrors,
          [data.name]: formErrorHandling(
            formValues[data.name],
            data.name,
            formValues,
            data.maxLength,
            data
          ),
        };
      }
    });
    setFormErrors(formerrors);
    // Checking the state errors
    if (new Set(Object.values(formerrors)).size === 1) {
      setLoading(true);
      // Reassigning the state keys with config fieldMap values
      const formField = retailerFormFields.reduce((field, form) => {
        const nameValue = form.name;
        if (form.name !== "passwordTwo") {
          if (form.name === "passwordOne") {
            field["password"] = formValues[nameValue];
          } else field[form.name] = formValues[nameValue];
        }
        return field;
      }, {});

      if (props.formType === "profile") {
        const updateUser = await updateUserCall(formField, retailerName);
        setLoading(false);
        if (updateUser.error) {
          setProfileUpdErrMsg({
            genricErrorMsg:
              "Your profile could not be updated, please check for errors and try again.",
          });
        } else {
          setSuccessMsg("Your profile has been updated successfully!");
          setProfileUpdErrMsg(null);
          dispatch(updateUserSuccess(retailerName));
        }
      } else {
        createUserCall(formField, retailerName).then((res) => {
          if (res.error) {
            const errorValue = res.fields[0];
            setLoading(false);
            switch (errorValue) {
              case "email":
                setFormErrors({
                  ...errors,
                  email: "Email address is already registered",
                });
                break;

              case "phone":
                if (Object.keys(formValues).includes("phone")) {
                  setFormErrors({
                    ...errors,
                    phone: `Phone number is already registered`,
                  });
                } else {
                  setFormError(res.error);
                }
                break;
              case "username":
                if(usernameValue[0].label.indexOf('Phone')!==-1){
                  setFormError('This phone number is already registered');
                } 
                else if(usernameValue[0].label.indexOf('email')!==-1){
                  setFormError('Your email address is already registered. Please log in to continue or use the Forgot Password option to reset your password.');
                }
                else {
                  setFormError(res.error);
                }
                break;
              default:
                setFormError(res.error);
            }
          } else {
            setLoading(false);
            navigate(`/${props.brandsiteName}/home`);
            localStorage.setItem("token", res);
            getUserInfo(res, retailerName).then((user) => {
              dispatch(accountActions.setUserDetails(user));
              localStorage.setItem("userData", JSON.stringify(user));
            });
          }
        });
      }
    }
  };

  // Grid values changing based on the fields.
  const handleMultiColumn = (data) => {
    let xs = "";
    if (data.name === "phone" || data.name === "email" ||  data.name === "username") {
      xs = 12;
    } else if (!data.isMultiColumn) {
      xs = 6;
    } else {
      xs = "";
    }
    return xs;
  };

  // Changing the field label based on the config language
  const getFieldLabel = (fields, locale) => {
    let newLabel = "";
    if (Object.keys(fields).indexOf("internationalization") > -1) {
      newLabel =
        fields.internationalization[locale] !== undefined
          ? fields.internationalization[locale]
          : fields.label;
    } else {
      newLabel = fields.label;
    }
    return newLabel;
  };

  // Checking the required field  based on the config fields
  const mandatoryField = (fields) => {
    const validation = fields.isRequired || fields.required;
    return validation;
  };

  // Adding the classe base on the device and errors
  const handleUsernameClass = (fieldValue) => {
    let txtFieldValue;
    if (errors[fieldValue]) txtFieldValue = classes.errortextfield;
    else txtFieldValue = classes.textField;
    return txtFieldValue;
  };

  // Adding the classe base on the device and errors
  const handleSelectClass = (fieldValue) => {
    let txtFieldValue;
    if (errors[fieldValue]) txtFieldValue = classes.selectBoxerror;
    else txtFieldValue = classes.selectBox;
    return txtFieldValue;
  };

  return (
    <Grid tabIndex={0} container>
      {retailerFormFields.length > 0 &&
        retailerFormFields.map((fields, idx) => (
          <Grid item xs={handleMultiColumn(fields)}>
            {/* Textfield start here */}
            {fields.field === "textField" && (
              // Excluding the fields based on the formtype and its relavant disable field value of the config
              <FormControl
                className={isMobile ? classes.marginMobile : classes.margin}
                variant="outlined"
              >
                <label htmlFor={getFieldLabel(fields)}>
                  <FormHelperText
                    className={classes.loyaltyId}
                    id="outlined-weight-helper-text"
                    aria-live="polite"
                  >
                    {getFieldLabel(fields)}{" "}
                    {mandatoryField(fields) && (
                      <span className={classes.mandatoryField}>*</span>
                    )}
                  </FormHelperText>
                </label>

                <TextField
                  variant="outlined"
                  id={getFieldLabel(fields)}
                  // Disabling the fields based on the formtype and its relavant disable field value of the config
                  disabled={fields.disabled}
                  type={
                    fields.name === "passwordOne" ||
                    fields.name === "passwordTwo"
                      ? "password"
                      : "string"
                  }
                  error={errors[fields.name] !== ""}
                  value={formValues[fields.name]}
                  onChange={handleChange(fields.name, fields)}
                  // Changing the End icon of the text field based on the error
                  InputProps={
                    errors[fields.name]
                      ? {
                          endAdornment: (
                            <InputAdornment position="start">
                              {errorIcon}
                            </InputAdornment>
                          ),
                        }
                      : ""
                  }
                  className={handleUsernameClass(fields.name)}
                />
              </FormControl>
            )}
            {/* Textfield end  here */}

            {/* SelectBox start  here */}
            {fields.field === "dropdown" && (
              // Excluding the fields based on the formtype and its relavant disable field value of the config
              <FormControl
                variant="outlined"
                className={isMobile ? classes.marginMobile : classes.margin}
              >
                <label htmlFor={`${idx}-dropdown`}>
                  <FormHelperText
                    className={classes.loyaltyId}
                    id="outlined-weight-helper-text"
                  >
                    {getFieldLabel(fields)}{" "}
                    {mandatoryField(fields) && (
                      <span className={classes.mandatoryField}>*</span>
                    )}
                  </FormHelperText>
                </label>

                {!formValues[fields.name] && (
                  <InputLabel
                    id={`${idx}-dropdown`}
                    className={
                      isMobile
                        ? classes.selectMobilePlaceholder
                        : classes.selectPlaceholder
                    }
                  >
                    Select
                  </InputLabel>
                )}
                <Select
                  labelId="demo-simple-select-label"
                  // Disabling the fields based on the formtype and its relavant disable field value of the config
                  disabled={fields.disabled}
                  id={`${idx}-dropdown`}
                  value={formValues[fields.name]}
                  error={errors[fields.name] !== ""}
                  inputProps={{
                    "aria-label": "Without label",
                    fontSize: "16px",
                  }}
                  // Changing the End icon of the Select box based on the error
                  IconComponent={() =>
                    errors[fields.name] ? (
                      <InputAdornment
                        position="end"
                        className={
                          formValues[fields.name]
                            ? classes.selectValueicon
                            : classes.selectIcon
                        }
                      >
                        {errorIcon}
                      </InputAdornment>
                    ) : (
                      <InputAdornment
                        position="end"
                        className={
                          formValues[fields.name]
                            ? classes.selectValueicon
                            : classes.selectIcon
                        }
                      >
                        {dropdownIcon}
                      </InputAdornment>
                    )
                  }
                  className={
                    formValues[fields.name]
                      ? classes.selectValueBox
                      : handleSelectClass(fields.name)
                  }
                  onChange={handleSelectChange(fields.name)}
                  onKeyDown={handleSelectChange(fields.name)}
                >
                  {fields.name === "state" &&
                    stateData[0].map((storeData) => (
                      <MenuItem value={storeData.value}>
                        {storeData.label}
                      </MenuItem>
                    ))}

                  {fields.name === "birthMonth" &&
                    birthMonth.map((storeData) => (
                      <MenuItem value={storeData.value}>
                        {storeData.label}
                      </MenuItem>
                    ))}
                  {fields.name === "birthDay" &&
                    birthDay.map((storeData) => (
                      <MenuItem value={storeData.value}>
                        {storeData.label}
                      </MenuItem>
                    ))}
                  {fields.name === "birthYear" &&
                    birthYear.map((storeData) => (
                      <MenuItem value={storeData.value}>
                        {storeData.label}
                      </MenuItem>
                    ))}
                  {fields.name === "country" &&
                    country.map((storeData) => (
                      <MenuItem value={storeData.value}>
                        {storeData.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            {/* SelectBox end  here */}
            {errors[fields.name] !== "" && (
              <FormHelperText
                tabIndex={0}
                className={
                  isMobile ? classes.errorMobile : classes.errorMessage
                }
                id="outlined-weight-helper-text"
              >
                {errors[fields.name]}
              </FormHelperText>
            )}
          </Grid>
        ))}
      {successMsg && (
        <Grid item xs={12}>
          <FormHelperText
            tabIndex={0}
            className={
              isMobile
                ? classes.alertBoxsucessMobile
                : classes.alertBoxsucesserror
            }
            id="outlined-weight-helper-text"
          >
            {successMsg}
          </FormHelperText>
        </Grid>
      )}
      <Grid item xs={12}>
        {profileUpdErrMsg && (
          <FormHelperText
            tabIndex={0}
            className={
              isMobile ? classes.alertBoxMobile : classes.alertBoxerror
            }
            id="outlined-weight-helper-text"
          >
            {profileUpdErrMsg && profileUpdErrMsg.errorMsg ? (
              <span>{profileUpdErrMsg.errorMsg}</span>
            ) : (
              profileUpdErrMsg.genricErrorMsg
            )}
          </FormHelperText>
        )}
      </Grid>

      {/* Changing the Button based on the form Type */}
      <Grid
        className={props.formType === "profile" ? classes.updateProfileBtn : ""}
        xs={12}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: props.formType === "profile" ? "flex-end" : "flex-start",
          marginLeft: `${isMobile ? "12px" : "25px"}`,
        }}
      >
        {/* signup Confirmation text */}
        {props.formType !== "profile" && (
          <Typography
            tabIndex={0}
            style={{ whiteSpace: "unset", textAlign: "left" }}
            className={classes.createAccount}
            noWrap
            data-testid="signUp"
          >
            {props?.confirmTxt}
          </Typography>
        )}

        <Button
          tabIndex={0}
          variant="contained"
          className={isMobile ? classes.signInbtnMobile : classes.signInbtn}
          onClick={handleCreate}
          disabled={isLoading}
          style={{ marginLeft: "auto" }}
        >
          {!isLoading ? (
            <div
              data-testid={
                props.formType === "profile" ? "updateProfileBtn" : "signBtn"
              }
              className="createAccount"
            >
              {props.formType === "profile" ? "Save Changes" : "Sign Up"}
            </div>
          ) : (
            <div className="loader-button" />
          )}
        </Button>
      </Grid>
      <Grid item xs={12}>
        {formError !== "" && formError !== undefined && (
          <FormHelperText
            tabIndex={0}
            className={
              isMobile ? classes.alertBoxMobile : classes.alertBoxerror
            }
            id="outlined-weight-helper-text"
          >
            {formError}
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  );
}

export default FormWrapper;
