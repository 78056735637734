import React, { useState } from "react";
import { makeStyles, createTheme, useMediaQuery } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Box, CardContent } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useNavigate } from "react-router-dom";
import { MonthNames } from "../../shared/util/constants";
import LoyaltyProgress from "../../MyLoyalty/LoyaltyProgress";

function LoyalityOffer(props) {
  const { retailerConfig, program, classNames } = props;

  const navigate = useNavigate();
  const loggedIn = !!localStorage.getItem("token");
  const [displayValue, setDisplayValue] = useState("");

  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const useStyles = makeStyles((theme) => ({
    root: {
      "& :hover": {
        backgroundColor: "transparent",
      },

      margin: "1.6rem 0.8rem",
      width: "100%",
      maxWidth: "320px ",
      background: "#55555",
      boxShadow: "none",
      background: "var(--Background-backgroundPrimary, #FFF)",

      [theme.breakpoints.between("sm", "md")]: {
        margin: "0.6rem",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "flex-start",
        borderRadius: "var(--radius--md, 8px)",
      },

      [theme.breakpoints.between("xs", "sm")]: {
        margin: "0.6rem",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "flex-start",
        borderRadius: "var(--radius--md, 8px)",
      },
    },
    media: {
      width: "140px",
      height: "140px",
      margin: "4px auto",
      display: "block",
      objectFit: "scale-down",

      [theme.breakpoints.between("xs", "sm")]: {
        height: "96px",
        width: "160px",
      },
    },
    contentContainer: {
      height: "170px",
      paddingBottom: "0",

      [theme.breakpoints.between("xs", "sm")]: {
        padding: "8px",
        height: "130px",
      },
    },
    title: {
      fontSize: "18px",
      fontWeight: "600",
      lineHeight: "initial",
      textAlign: "left",
      // height: '50px',
      overflow: "hidden",
      textOverflow: "ellipsis",
      alignSelf: "stretch",
      padding: "4px 0px",

      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "14px",
        lineHeight: "150%",
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
      },
    },
    points: {
      color: "#0073C5",
      fontSize: "18px",
      fontWeight: "700",
      paddingBottom: "4px",
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "12px",
      },
    },
    content: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "140%",
      textAlign: "left",
      color: "#25272C",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      padding: "4px 0px",
      // height: "58px",
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "12px",
        lineHeight: "150%",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        height: "44px",
      },
    },
    date: {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "25.2px",
      textAlign: "left",
      color: "#6B7280",
      lineHeight: "140%",
      padding: "4px 0px",
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "12px",
        lineHeight: "120%",
      },
    },
    buttonContainer: {
      width: "100%",
      padding: "0 10px",

      [theme.breakpoints.between("xs", "sm")]: {
        padding: "10px 8px",
      },
    },
    actionBtn: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      outline: "none",
      margin: "10px 0",
      borderRadius: "16px",
      fontSize: "16px",
      fontFamily: "InterBold",
      width: "100%",
      textTransform: "capitalize",
      fontWeight: "600",

      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        margin: "0",
      },
    },
  }));
  const classes = useStyles();

  function DaylightSavingTimeAdjustment(localeDate) {
    const date = new Date(localeDate);
    if (date.getHours() == 0) {
      date.setHours(date.getHours() - 1);
    }
    return date;
  }

  const expirationOfferDate = DaylightSavingTimeAdjustment(
    new Date(program.expirationDate).toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    })
  );

  const expirationMonth = MonthNames[new Date(expirationOfferDate).getMonth()];
  const expirationDate = `${new Date(
    expirationOfferDate
  ).getDate()}, ${new Date(expirationOfferDate).getFullYear()}`;

  // calculating dates end //
  function enroll(id) {
    if (!loggedIn) {
      navigate(`/${retailerConfig.brandsite_name}/login`);
    }
    {
      props.enrollCurrentProgram(id);
    }
  }

  function setValue(val) {
    const displayUnit = program.pointsDisplayUnit;
    if (displayUnit.includes("spend")) {
      setDisplayValue( `$ ${displayUnit === 'spend_cents' ? (val/100) : val}`);
    }
    if (displayUnit.includes("item")) {
      setDisplayValue(`${val} items`);
    }
    if (displayUnit.includes("point")) {
      setDisplayValue(`${val} points`);
    }
    if (val === undefined) {
      setDisplayValue(`Completed`);
    }
  }

  const getTitle = (program) => {
    if (program.pointsDisplayUnit.includes("spend")) {
      const rewardValue = program.rewardGroups.length > 0 && program.rewardGroups[0].rewards.length > 0 
      && program.rewardGroups[0].rewards[0].rewardValue;

      return rewardValue ? `Get $${program.pointsDisplayUnit === 'spend_cents' ? rewardValue / 100 : rewardValue} Cashback` : '-';
    }

    if (program.pointsDisplayUnit.includes("points")) {
      const rewardValue = program.rewardGroups.length > 0 && program.rewardGroups[0].rewards.length > 0 
      && program.rewardGroups[0].rewards[0].offer && program.rewardGroups[0].rewards[0].offer.length > 0 && 
      program.rewardGroups[0].rewards[0].offer.value;

      return rewardValue ? `Get ${rewardValue} Points` : '-';
    }
  }

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <Box>
          <img className={classes.media} src={program.imageUrl} />
        </Box>

        <CardContent className={classes.contentContainer}>
          <Typography className={classes.points}>
            {program.qualifyingOffers[0]?.brand}
            </Typography>
          <Typography className={classes.title}>
            {getTitle(program)}
          </Typography>
          <Typography className={classes.content}>
            {program.qualifyingOffers[0]?.shortDescription ? program.qualifyingOffers[0]?.shortDescription : "-"}
          </Typography>
          <Typography className={classes.date}>
            Expires: {expirationMonth.substring(0, 3)} {expirationDate}
          </Typography>
        </CardContent>
      </CardActionArea>

      {program.status === "available" && (
        <div
          className={classNames ? `${classes.buttonContainer} + ' ' + ${classNames}` : classes.buttonContainer}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {
            <Button
              className={classes.actionBtn}
              style={{ background: "#2596be" }}
              onClick={() => enroll(program.id)}
            >
              {loggedIn ? "Enroll" : "Login To Enroll"}
            </Button>
          }
        </div>
      )}

      {program.status !== "available" && (
        <LoyaltyProgress
          setValue={setValue}
          offerInfo={program}
          color={"#32CD32"}
        ></LoyaltyProgress>
      )}
    </Card>
  );
}

export default LoyalityOffer;
