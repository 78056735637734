import React from 'react';
import { trackInfluencerClick } from '../utils/gaTrackingFunctions';
import './instagram.scss';

function InstagramSection({ component }) {
  let border_radius = component.has_radius_border;
  let igHeader = component.ig_header;
  let headerTextColor = component.header_text_color;

  if (!component || !component.instagrampost_set) {
    return null; // Return something or render a placeholder if the block is not found
  }

  return (
    <>
      {igHeader && igHeader.trim() !== '' && (
        <div className="tw-w-full tw-max-w-[1440px] tw-mx-auto tw-px-4 tw-py-8 tw-flex tw-flex-col tw-justify-start tw-items-center tw-gap-4">
          <p className={`tw-text-center tw-text-zinc-800 tw-text-4xl tw-font-extrabold tw-font-['Switzer Variable'] tw-leading-tight sm:tw-text-5xl md:tw-text-6xl lg:tw-text-7xl ${igHeader ? 'noBottomPadding' : ''}`} style={{ color: headerTextColor }}>
            {igHeader}
          </p>
        </div>
      )}
      <div className="instagram-section">
        {component.instagrampost_set.map((post, index) => (
          <InstagramPost key={index} post={post} index={index} border_radius={border_radius} />
        ))}
      </div>
    </>
  );
}


function InstagramPost({ post, index, border_radius }) {
  const { image, alt_text, text, url } = post;

  function mediaLink(type, url, img_path, title) {
    switch (type) {
      case 'pinterest':
        return `https://www.pinterest.com/pin/create/button/?url=${url}&media=${img_path}&description=${encodeURIComponent(
          title
        )}`;
      case 'facebook':
        return `https://www.facebook.com/share.php?u=${url}&title=${encodeURIComponent(
          title
        )}`;
      case 'twitter':
        return `http://twitter.com/share?text=${encodeURIComponent(
          title
        )}&url=${url}`;
      default:
        return '';
    }
  }

  function handleClick() {
    trackInfluencerClick(url, index + 1, text, 'instagram');
  }

  const styles = {
    margin: '10px',
    borderRadius: border_radius === "true" ? '24px' : '0',
  };

  return (
    <div className="instagram-post" style={styles}>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
      >
        <img src={image} alt={alt_text} className="instagram-img" />
      </a>
      <div className='footer'>
        <div className="instagram-text">{text}</div>
        <div className="social-icons">
          {['pinterest', 'facebook', 'twitter'].map((media, i) => (
            <a
              href={mediaLink(media, url, image, text)}
              target="_blank"
              className="btn-social"
              key={i}
              rel="noopener noreferrer"
              onClick={() => trackInfluencerClick(mediaLink(media, url, image, text), index + 1, text, media)}
            >
              <i className={`fa fa-${media}`} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default InstagramSection;
