import React, { useState } from 'react';
import './Rebates.scss';
import { requestPresignedUrls, uploadFilesToS3 } from '../utils/s3Utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import { trackCashbackReceiptSubmit, trackCashbackReceiptSubmitError } from '../utils/gaTrackingFunctions';

function PreviewSubmission({
  page,
  formData,
  edit,
  uploadedFiles,
  paymentMethod,
  rebatesData,
  clearForm,
  setFormSubmitted,
  closeForm,
  setShowFailedPopUp,
  onSuccess,
}) {
  const block = page.components.find(component => component.block_type === 'Rebates');
  setFormSubmitted(false);
  let paymentTypeId;
  if (paymentMethod === 'PayPal') {
    paymentTypeId = 1;
  } else if (paymentMethod === 'Venmo') {
    paymentTypeId = 2;
  } else {
    paymentTypeId = 0;
  }

  const triggerSuccessPopup = (updatedSubmissionNumber) => {
    closeForm();
    const data = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      paymentMethod: paymentMethod,
      paymentEmail: formData.paypalemail,
      venmoNumber: formData.venmoNumber,
      submissionNumber: updatedSubmissionNumber
    };
    onSuccess(data);
  };

  const triggerFailurePopup = () => {
    closeForm();
    setShowFailedPopUp(true);
  };

  const formattedDate = new Date().toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });

  const [isLoading, setIsLoading] = useState(false);

  const fileNames = uploadedFiles.map(file => file.name);

  const handleCancelClick = (e) => {
    clearForm();
    closeForm();
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    requestPresignedUrls(fileNames)
      .then(presignedUrls => {
        return uploadFilesToS3(uploadedFiles, presignedUrls).then(uploadResults => {
          return uploadResults.map((result, index) => {
            return {
              ...result,
              presignedUrl: presignedUrls[index].url,
              originalFileName: fileNames[index]
            };
          });
        });
      })
      .then(uploadResults => {
        const paymentTypeId = paymentMethod === 'PayPal' ? 1 : 2;
        const cleanVenmoNumber = formData.venmoNumber ? formData.venmoNumber.replace(/\D/g, '') : null;
        const payoutAccount = paymentMethod === 'PayPal' ? formData.paypalemail : cleanVenmoNumber;
  
        const request_data = {
          fileInfos: uploadResults,
          submissionRequest: {
            offerCode: rebatesData.offerCode,
            paymentTypeID: paymentTypeId
          },
          submitters: [{
            email: formData.email || null,
            firstName: formData.firstName || null,
            lastName: formData.lastName || null,
            phone: paymentTypeId === 2 ? cleanVenmoNumber : null,
            AccountType: paymentTypeId,
            PayoutAccount: payoutAccount || null
          }],
          action: 'submit_receipt',
          clientNumber: block.client_number,
        };
  
        const requestData = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(request_data),
        };
        return fetch(process.env.REACT_APP_REBATE_SUBMISSION_LAMBDA, requestData)
          .then(response => response.json())
          .then(responseJson => {
            const responseSubmissionNumber = responseJson.response.submissionNumber;
            console.log(responseSubmissionNumber);
            setIsLoading(false);
            trackCashbackReceiptSubmit(rebatesData.offerCode, responseSubmissionNumber);
            triggerSuccessPopup(responseSubmissionNumber);
          });
      })
      .catch(error => {
        console.error("Error:", error);
        setIsLoading(false);
        trackCashbackReceiptSubmitError(rebatesData.offerCode, error.message || 'Unknown error');
        triggerFailurePopup();
      });
  };

  const renderFilePreviews = () => {
    return (
      <div>
        {uploadedFiles.map((file, index) => (
          <div className='receipt-upload-container' key={index}>
            <div className='image-column'>
              <div className='file-preview'>
                <img src={URL.createObjectURL(file)} alt={`Uploaded Image ${index + 1}`} />
              </div>
            </div>
            <div className='info-column'>
              <div className='receipt-file-name'>{file.name}</div>
              <div className='receipt-upload-date'>Uploaded on {formattedDate}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div style={{backgroundColor: rebatesData.color_background_2}}>
        <div className='submission-form-column-right'>
          <div className='submission-form-header'>
            <h2>REVIEW SUBMISSION</h2>
          </div>
          <div className="container mt-1 pb-3">
            <form>
              <div className="mb-3 label-left">
                <label htmlFor="firstName" className="form-label rebates-form-label">First Name <span className='red'>*</span></label>
                <div>{formData.firstName}</div>
              </div>
              <div className="mb-3 label-left">
                <label htmlFor="lastName" className="form-label rebates-form-label">Last Name <span className='red'>*</span></label>
                <div>{formData.lastName}</div>
              </div>
              <div className="mb-3 label-left">
                <label htmlFor="email" className="form-label">Email <span className='red'>*</span></label>
                <div>{formData.email}</div>
              </div>
              <div className='with-spacing'>
                <h3 className='product-upc'>PAYMENT METHOD</h3>
                <p className='product-upc-desc'>{paymentMethod === 'PayPal' ? 'PayPal' : 'Venmo'}</p>
                {paymentMethod === 'PayPal' && (
                  <div className="mb-3 label-left">
                    <label htmlFor="email" className="form-label">
                      <strong>PayPal Email </strong><span className='red'>*</span>
                    </label>
                    <div>{formData.paypalemail}</div>
                  </div>
                )}
                {paymentMethod === 'Venmo' && (
                  <div className="mb-3 label-left">
                    <label htmlFor="phone" className="form-label">
                      <strong>Venmo Phone </strong><span className='red'>*</span>
                    </label>
                    <div>{formData.venmoNumber}</div>
                  </div>
                )}
              </div>
              <div className='with-spacing'>
                <h3 className='product-upc'>RECEIPT UPLOAD</h3>
                {renderFilePreviews()}
              </div>
              <div className="row col with-spacing">
                <div className="form-check mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="agreeToTerms"
                    defaultChecked={true}
                    disabled
                  />
                  <label className="form-check-label" htmlFor="agreeToTerms">
                    I agree to the <a href="#">Terms and Conditions</a> and to receive emails that are relevant to my rebate.<span className='red'>*</span>
                  </label>
                </div>
              </div>
              <div className='with-spacing'></div>
              <div className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-end tw-gap-4 tw-inline-flex tw-items-center tw-w-full tw-justify-center lg:tw-justify-center">
                <div className="tw-text-[#0071ce] tw-text-xl tw-font-bold tw-font-['Roboto'] tw-uppercase tw-cursor-pointer" onClick={handleCancelClick}>
                  Cancel
                </div>
                <div
                  className="tw-h-[71px] tw-w-[170px] tw-px-12 tw-py-6 tw-bg-white tw-rounded-xl tw-justify-center tw-items-center tw-gap-2.5 tw-inline-flex tw-cursor-pointer"
                  onClick={edit}
                  style={{ border: '1px solid #0071ce' }}
                >
                  <div className="tw-text-[#0071ce] tw-text-xl tw-font-bold tw-font-['Roboto'] tw-uppercase">Edit</div>
                </div>
                <div className="tw-h-[71px] tw-px-12 tw-py-6 tw-bg-[#0071ce] tw-rounded-xl tw-justify-center tw-items-center tw-gap-2.5 tw-inline-flex tw-cursor-pointer" onClick={handleSubmitClick}>
                  <div className="tw-text-center tw-text-white tw-text-xl tw-font-bold tw-font-['Roboto'] tw-uppercase">Submit</div>
                </div>
              </div>

              {isLoading && (
                <div className="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full tw-fixed tw-inset-0">
                  <CircularProgress />
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreviewSubmission;