export function getBaseUrl (retailer, isV2Api) {
    switch (process.env.REACT_APP_ENV) {
    case 'prod':
        return `https://${retailer}.ice.dpn.inmar.com/v2`;
    // Dev & Stage
    default:
        return `https://${retailer}.ice.test.dpn.inmar.com/${isV2Api ? 'v2' : 'v3'}`;
    }
}

export const getRoutePrefix = () => {
    const isPreviewMode = process.env.REACT_APP_PREVIEW_MODE === "true";
    
    if (isPreviewMode) {
      const pathParts = window.location.pathname.split('/');
      return pathParts.length > 1 ? `/${pathParts[1]}` : '';
    }
    
    return '';
  };
  
  export const getFullPath = (path) => {
    const basePrefix = getRoutePrefix();
    return `${basePrefix}${path}`;
  };

  export const getFullUrlPath = (path) => {
    const basePrefix = getRoutePrefix();
    const fullDomain = `${window.location.protocol}//${window.location.host}`;
    return `${fullDomain}${basePrefix}${path}`;
  };