import { EmailRgx } from "./constants";

export const validateStandard = (value, fieldValue) => {
  let error = "";
  if (value == "" || value == undefined) {
    error = fieldValue
      ? `${fieldValue} is required.`
      : "Field must not be blank";
  }
  return error;
};

export const validateEmailAddress = (value, component) => {
  let error = "";
  if (component && value === "") {
    error = "Email address is required";
  } else {
    // checks for common validation
    error = validateStandard(value);
  }

  // return re.test(String(email).toLowerCase());
  if (value && value !== "" && !EmailRgx.test(value.toLowerCase())) {
    error = "Incorrect email format";
  }
  return error;
};

export const validatePassword = (value, isLoginPwd) => {
  let error = "";
  if (value === undefined || value === "") {
    error = validateStandard(value, "Password");
    return error;
  }

  if (isLoginPwd) {
    return value.length > 7 ? "" : "Please enter valid password";
  }

  if (
    value.length > 0 &&
    !value.match(
      /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/
    )
  ) {
    error =
      "Your password must be at least 8 characters long and include at least 1 uppercase, special character, and a number.";
  }
  return error;
};

export const validatePhoneNumber = (value) => {
  let error = "";
  // checks for common validation
  error = validateStandard(value);
  if (value !== "" && error === "") {
    if (value && value.length !== 10) {
      error = "Phone must be 10 digits";
    }
    if (
      value &&
      (value.indexOf("-") > -1 ||
        value.indexOf("(") > -1 ||
        value.indexOf(")") > -1)
    ) {
      error = "Field can only contain numbers";
    }
  }
  return error;
};

export const validateUserName = (value, userNameKey) => {
  let error = "";
  // Shortcut for not displayed
  error = validateStandard(value, userNameKey);
  if (value.length > 0) {
    let userName = Object.assign(value);
    if (userName.indexOf("@") === -1 && userNameKey === "phone") {
      // is not email and is phone for user name so strip () -
      while (userName.indexOf(")") > -1) {
        userName = Object.assign(userName.replace(")", ""));
      }
      while (userName.indexOf("(") > -1) {
        userName = Object.assign(userName.replace("(", ""));
      }
      while (userName.indexOf("-") > -1) {
        userName = Object.assign(userName.replace("-", ""));
      }
      while (userName.indexOf(" ") > -1) {
        userName = Object.assign(userName.replace(" ", ""));
      }
      error = validatePhoneNumber(userName, true, true);
    } else if (!(userName.indexOf("@") === -1)) {
      error = validateEmailAddress(userName);
    } else {
      error = validateStandard(value, userNameKey);
    }
  }
  return error;
};

export const getUsernameLabel = (retailerConfig, locale) => {
  let retVal = "";
  // if internationalization is present
  // Have to do it this way for legacy retailers or when they do not specify this
  if (
    Object.keys(retailerConfig).indexOf(
      "loginPlaceHolderTextInternationalization"
    ) > -1
  ) {
    retVal = retailerConfig.loginPlaceHolderTextInternationalization[locale];
  } else {
    retVal = retailerConfig.loginPlaceHolderText;
  }
  return retVal;
};

export const validateZip = (value, length, formValues, data) => {
  let error = "";
  // checks for common validation
  if (data.validationType === "string") {
    if (length !== undefined && value.length !== length) {
      error = "Zip code does not meet the required number";
    }
  }
  if (data.validationType === "zip") {
    if (formValues.country === "CA") {
      const valueLength =
        length !== undefined ? value.length !== length : value.length !== 7;
      error = valueLength ? "Zip code does not meet the required number" : "";
    }
    if (formValues.country === "US") {
      const valueLength =
        length !== undefined ? value.length !== length : value.length !== 5;
      error =
        valueLength || isNaN(value)
          ? "Zip code does not meet the required number"
          : "";
    }
    if (formValues.country === " ") {
      const valueLength =
        length !== undefined ? value.length !== length : value.length !== 5;
      error = valueLength ? "Zip code does not meet the required number" : "";
    }
  }
  return error;
};

export const validatePasswordTwo = (value, key, formvalues) => {
  let passwordError = "";
  if (value !== formvalues[key]) passwordError = "Passwords do not match";
  return passwordError;
};

export const validateConfirmPwd = (value1, value2) => {
  let passwordError = "";
  if (value1 !== value2) {
    passwordError = "Passwords do not match";
  }
  return passwordError;
};

// Error Handling based on the validationtype
export const formErrorHandling = (value, key, formValues, maxLength, data) => {
  let error = "";
  switch (key) {
    case "email":
      error = validateEmailAddress(value);
      break;
    case "passwordOne":
      error = validatePassword(value);
      break;
    case "passwordTwo":
      error = validatePasswordTwo(value, "passwordOne", formValues);
      break;
    case "phoneNumber":
      error = validatePhoneNumber(value);
      break;
    case "zip":
      error = validateZip(value, maxLength, formValues, data);
      break;
  }
  return error;
};

// Constructing the inital form fields
export const formFieldsConfig = (dataValue, formType) => {
  const fieldsData = JSON.parse(JSON.stringify(dataValue));
  const fields = fieldsData.filter((data) => {
    if (formType === "register" && !data.excludeOnRegistration) {
      data.disabled = data.disabledOnRegistration;
      data.required = data.isRequiredRegistration;
      ["excludeOnProfile", "disabledOnProfile", "isRequiredProfile"].forEach(
        (prop) => delete data[prop]
      );
      return data;
    }

    if (
      formType === "profile" &&
      !["homeStoreIds", "charity"].includes(data.fieldMap) &&
      !data.excludeOnProfile
    ) {
      data.isRequired = data.isRequired || data.isRequiredProfile;
      data.disabled = data.disabledOnProfile;
      [
        "excludeOnRegistration",
        "disabledOnRegistration",
        "isRequiredRegistration",
      ].forEach((prop) => delete data[prop]);
      return data;
    }
  });

  return fields;
};

// Reassigning the state keys with config fieldMap values for profile
const createFieldMapToNameMap = (fieldValues) => {
  const fieldMapeMap = {};
  for (const field of fieldValues) {
    fieldMapeMap[field.name] = field.name;
  }

  return fieldMapeMap;
};

// Assigning the json form fields data into the state Variable
export const stateFieldConfig = (fieldValue, error, formType, userData) => {
  if (formType === "register") {
    const formField = fieldValue.reduce((field, form) => {
      if (form.isMetadata) {
        if (form.defaultValue) {
          field[form.name] = form.defaultValue;
        } else {
          field[form.name] = form.type == "boolean" ? false : "";
        }
      } else {
        field[form.name] = "";
      }
      return field;
    }, {});

    return formField;
  }

  // formType - Profile
  const fieldMapToNameMap = createFieldMapToNameMap(fieldValue);
  const formField = fieldValue.reduce((field, form) => {
    if (form.isMetadata) {
      if (userData && userData.metadata) {
        field[form.name] = userData.metadata[form.fieldMap]
          ? userData.metadata[form.fieldMap]
          : field.defaultValue;
      } else {
        field[form.name] = field.defaultValue ? field.defaultValue : "";
      }
    } else {
      field[form.name] = userData ? userData[fieldMapToNameMap[form.name]] : "";
    }
    return field;
  }, {});

  return formField;
};
