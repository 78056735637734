import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  roundedBox: {
    width: '183px',
    gap: '0',
    backgroundColor: '#ffffff',
    borderRadius: '24px',
    border: '1px solid #B6BAC3',
    boxShadow: '0px 170px 48px 0px rgba(29, 60, 109, 0.00), 0px 109px 44px 0px rgba(29, 60, 109, 0.01), 0px 61px 37px 0px rgba(29, 60, 109, 0.05), 0px 7px 15px 0px rgba(29, 60, 109, 0.10), 0px 0px 0px 0px rgba(29, 60, 109, 0.10)',
    [theme.breakpoints.up('sm')]: {
      width: '294px',
    },
  },
  imageContainer: {
    padding: theme.spacing(1),
    height: '98px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      height: '188px',
    },
  },
  image: {
    marginTop: '8px',
    width: '96px',
    height: '96px',
    objectFit: 'cover',
    [theme.breakpoints.up('sm')]: {
      marginTop: '16px',
      width: '186px',
      height: '186px',
    },
  },
  content: {
    padding: theme.spacing(2),
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      padding: '16px 24px 24px 24px',
      gap: '2px',
    },
  },
  title: {
    gap: theme.spacing(1),
    fontFamily: 'Switzer',
    fontSize: '13.5px',
    fontWeight: '700',
    lineHeight: '160%',
  },
  description: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    color: '#25272C',
    fontFamily: 'Arial, sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '160%',
    gap: '2px',
    minHeight: '3.2em',
    marginTop: '12px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
  },
  expiration: {
    color: '#6B7280',
    fontFamily: 'Switzer',
    fontSize: '13.5px',
    lineHeight: '150%',
    [theme.breakpoints.up('sm')]: {
      lineHeight: '160%',
    },
  },
  button: {
    padding: theme.spacing(3),
    gap: '10px',
    backgroundColor: '#0071CE',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '160%',
    width: '100%',
    borderRadius: '0 0 24px 24px',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      fontFamily: 'Arial Black',
      fontWeight: '900',
      fontSize: '18px',
    },
  },
}));

const OfferCard = ({ offer, onSubmitReceiptClick }) => {
  const classes = useStyles();
  const { offerDescription, offerEndDate, offerTitle, imageLink, offerCode } = offer;

  return (
    <Box className={classes.roundedBox}>
      <Box className={classes.imageContainer}>
        <img className={classes.image} src={imageLink} alt="Product" />
      </Box>
      <Box className={classes.content}>
        <Typography className={classes.title}></Typography>
        <Typography className={`${classes.description} ${classes.twoLineDescription}`}>
          {offerTitle}
        </Typography>
        <Typography className={classes.expiration}>Expires: {offerEndDate}</Typography>
      </Box>
      <button className={classes.button} onClick={onSubmitReceiptClick}>Submit Receipt</button>
    </Box>
  );
};

export default OfferCard;
