import React from "react";
import { useSelector } from "react-redux";
import OfferItem from "./Item";
import BulkOfferClip from "../offers/bulkOfferClip";
import "./offersV2.scss";
import { useParams } from "react-router-dom";

const OffersV2 = (props) => {
  const params = useParams();
  const siteConfig = props.page
  const offerBlock = siteConfig.components.find(component => component.block_type === 'OfferBlock');
  let isLoading = !params.id ? false : true,
    queryId = params ? params.id : false,
    deepLinkOffer = params.id ? findOffer(params.id) : "";
  let available = useSelector((state) => state.offer.collections.available);
  let featured = useSelector((state) => state.offer.collections.featured);
  let links = siteConfig.colorLinks;
  const isMobileTwoColumns = offerBlock.use_two_column_mobile;
  let headerTextColor = offerBlock.title_text_color;

  function findOffer (id) {
    let deepLinkOffer = available.find(
      (cpn) => cpn.mdid === parseInt(id)
    );
    isLoading = false;
    return deepLinkOffer;
  }

  function RenderDeepLink (props) {
    const { cpn, retailer } = props;
    return (
      <div>
        <div className="SingleOffer">
          <OfferItem coupon={cpn} key={cpn.mdid} page={siteConfig} retailer={retailer} />
        </div>
      </div>
    );
  }

  function RenderOfferList (props) {
    const { retailer } = props;

    return (
      <div className="offerContent2">
        <div className="tw-w-full tw-max-w-[1440px] tw-mx-auto tw-px-4 tw-py-8 tw-flex tw-flex-col tw-justify-start tw-items-center tw-gap-4">
          <div className="tw-text-center tw-text-zinc-800 tw-text-4xl tw-font-extrabold tw-font-['Switzer Variable'] tw-leading-tight sm:tw-text-5xl md:tw-text-6xl lg:tw-text-7xl" style={{ color: headerTextColor }}>{offerBlock.coupon_title_text ? <p>{offerBlock.coupon_title_text}</p> : <p>In Store Digital Coupons</p>} </div>
          <div className="tw-w-full tw-max-w-[680px] tw-text-center tw-text-zinc-800 tw-text-base tw-font-normal tw-font-['Switzer Variable'] tw-leading-snug sm:tw-text-lg md:tw-text-xl" style={{ color: headerTextColor }}>{offerBlock.coupon_subtitle_text ? <p>{offerBlock.coupon_subtitle_text}</p> : <p>Your {props.retailer.internal_name} savings journey starts here – clip coupons to use in-store!</p>}</div>
        </div>
        {offerBlock && offerBlock.use_bulk_offer_clip && (
          <BulkOfferClip siteConfig={siteConfig} retailer={props.retailer}/>
        )}
        <div className={`OfferList ${isMobileTwoColumns ? 'mobile-two-columns' : ''}`}>
          {props.available.map((cpn) => {
            return <OfferItem 
              retailer={retailer}
              coupon={cpn} 
              key={cpn.mdid}
              page={props.page}
              isMobileTwoColumns={isMobileTwoColumns} />;
          })}
        </div>
      </div>
    );
  }

  function RenderFeaturedList(props) {
    return (
      <div className="FeaturedOfferList">
        {props.featured.map((cpn) => {
          return <OfferItem coupon={cpn} key={cpn.mdid} isFeatured={true} page={props.page} retailer={props.retailer}
          />;
        })}
      </div>
    );
  }

  function trackViewMoreEvent (){
    window.gtag('event', 'View More Coupons', { 
      retailer_id: props.retailer.dpn_instance,
      version: localStorage.getItem("AB_TEST_VARIABLE") || 'N/A'
    });
  }

  if (isLoading) {
    return "";
  }

  //displays offer-section of page
  if (queryId && deepLinkOffer) {
    return <RenderDeepLink cpn={deepLinkOffer} page={siteConfig} retailer={props.retailer} />;
  } else if (!queryId && (available.length > 0 || featured.length > 0)) {
    return (
      <div className="offer-section" style={{ color: links, paddingBottom: "1%" }}>
        {featured.length > 0 && <RenderFeaturedList featured={featured} page={siteConfig} retailer={props.retailer}/>}
        <RenderOfferList available={available} page={siteConfig} retailer={props.retailer}/>
        <br></br>
        {offerBlock && offerBlock.show_view_more_coupons && (
        <a
          className="viewMore"
          href={props.retailer.digital_coupons_link}
          onClick={trackViewMoreEvent}
          style={{ color: links }}
        >
          View more {props.retailer.internal_name} digital coupons...
        </a>
      )}
      </div>
    );
  } else {
    return (
      <div className="offer-section">
        <p>There are no available offers</p>
      </div>
    );
  }
};

export default OffersV2;
