import axios from 'axios';
import store from '../../redux/index';
import { SET_REBATES_TOKEN, CLEAR_REBATES_TOKEN } from '../../redux/reducers/user';

async function getValidToken() {
  const state = store.getState();
  const { rebatesToken, rebatesTokenExpiry } = state.user;

  if (rebatesToken && rebatesTokenExpiry) {
    const now = Date.now();
    const timeUntilExpiry = rebatesTokenExpiry - now;

    if (timeUntilExpiry > 3600000) {
      return rebatesToken;
    }
  }

  return await getNewToken();
}

async function getNewToken() {
  const myHeaders = new Headers();
  const raw = JSON.stringify({
    "action": "get_token"
  });
  
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  try {
    const response = await fetch(process.env.REACT_APP_REBATE_SUBMISSION_LAMBDA, requestOptions);
    const result = await response.json();

    store.dispatch(SET_REBATES_TOKEN({
      token: result.token,
      expiry: Date.now() + 86400000
    }));

    return result.token;
  } catch (error) {
    console.error("Error fetching new token:", error);
    throw error;
  }
}

async function makeAuthenticatedRequest(config) {
  try {
    const token = await getValidToken();
    config.headers['Authorization'] = `Bearer ${token}`;
    const response = await axios(config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      store.dispatch(CLEAR_REBATES_TOKEN());
      const newToken = await getNewToken();
      config.headers['Authorization'] = `Bearer ${newToken}`;
      const retryResponse = await axios(config);
      return retryResponse.data;
    }
    console.error("makeAuthenticatedRequest: Error occurred", error);
    throw error;
  }
}

export { getValidToken, makeAuthenticatedRequest };