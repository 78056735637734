import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_LOGIN, CLIP_OFFER } from '../redux/reducers/offer';
import { SET_USER } from '../redux/reducers/user';
import { Checkbox} from '@material-ui/core';
import ErrorModal from '../errorModal';
import ProgressBlock from '../progress';
import { shouldRenderComponent } from '../shared/util/ComponentAuth';
import "./loyalty.scss";

function LoyaltyBlock(page) {
  const siteConfig = page.page;
  const block = siteConfig.components.find(component => component.block_type === 'LoyaltyBlock');
  const metaBlock = siteConfig.components.find(component => component.block_type === 'MetaDataBlock');
  const enrollment = useSelector((state) => state.user.enrollment);
  const user = useSelector((state) => state.user.username);
  const retailerConfig = useSelector((state) => state.config.retailerConfig);
  const retailer = page.dpn_instance ? page.dpn_instance : retailerConfig.dpn_instance;
  const {terms_and_conditions_Link ,terms_and_conditions_requirement } = block

  const dispatch = useDispatch();

  const [enrollmentButtonClicked, setEnrollmentButtonClicked] = useState(false);
  const [validationSuccessful, setValidationSuccessful] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [enrollmentError, setEnrollmentError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState();
  const [checkbox, setCheckBox] = useState(false);

  const funnel = metaBlock.funnel || 'n/a';
  const channel = metaBlock.channel || 'n/a';
  const qualifying_offer_id = block.qualifying_offer_id;

  useEffect(() => {
    if (validationSuccessful && enrollmentButtonClicked) {
      trackEnrollmentEvent();
      window.fbq('track', 'CompleteRegistration');
    } else if (validationSuccessful) {
      trackLoginEvent();
    }
  }, [validationSuccessful, enrollmentButtonClicked, email]);

  useEffect(()=>{
    // tracking gtag event for agree_to_terms
    window.gtag('event', 'agree_to_terms', {  version: localStorage.getItem("AB_TEST_VARIABLE") || 'N/A', checked: checkbox });
}, [checkbox])

  if (!shouldRenderComponent(block.auth_visibility)) {
    return null;
  }

  const handleClose = () => {
    dispatch(SHOW_LOGIN({ show: false }));
  };

  const handleErrorClose = () => {
    setShowError(false);
  };
  const handleCheckBoxChange= ()=>{
    if(!checkbox){
      setLoginError(false)
    }
    setCheckBox(!checkbox)
  }

  async function enrollEmail(email, email_debug = false, resp_status) {
    console.log(resp_status)
    const lambda_url = process.env.REACT_APP_SFMC_LAMBDA;
    const list_id = block.sfmc_list_id;
    const customer_key = block.sfmc_customer_key;
  
    try {
      const enrollResponse = await fetch(`${lambda_url}?action=add_to_enroll_list&email=${email}&retailer=${retailer.dpn_instance}&list_id=${list_id}`);
      const enrollData = await enrollResponse.json();
      console.log(enrollData.status_message);
  
      if (enrollData.status_code === 'OK' && resp_status === 200) {
        console.log('User successfully enrolled or debug enabled, attempting to send notification email');
        const triggerResponse = await fetch(`${lambda_url}?action=trigger_email&email=${email}&retailer=${retailer.dpn_instance}&customer_key=${customer_key}`);
        const triggerData = await triggerResponse.json();
        console.log(triggerData.status_message);
      } else if (enrollData.status_message === 'The subscriber is already on the list') {
        console.log('The subscriber is already on the list, not sending email');
      }
    } catch (error) {
      console.error(`Error enrolling email: ${email}`, error);
    }
  }
  

  function checkEmailValidation(e) {
    const emailInput = e.target.value;
    const emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (emailInput === '' && phoneNumber === '') {
      setLoginError(false);
    } else if (emailInput === '') {
      setLoginError('Email is required');
    } else if (emailRegexp.test(emailInput)) {
      setLoginError(false);
      setEmail(emailInput);
    } else {
      setLoginError('Please enter a valid email address');
    }
  }

  function checkPhoneNumberValidation(e) {
    const phoneNumberInput = e.target.value;
    const phoneRegexp = /^(\d{10}|\d{3}[-\s]\d{3}[-\s]\d{4}|\(\d{3}\)[-.\s]\d{3}[-.\s]\d{4}|\d{3}[-\s]\d{4})$/;

    if (phoneNumberInput === '' && email === '') {
      setLoginError(false);
    } else if (phoneNumberInput === '') {
      setLoginError('Phone number is required');
    } else if (phoneRegexp.test(phoneNumberInput)) {
      setLoginError(false);
      setPhoneNumber(phoneNumberInput);

    } else {
      setLoginError('Phone number must be 10 digits');
    }
  }

  async function checkUserExistence(id, phoneNumber, email) {
    let baseUrl = "";
  
    const env = process.env.REACT_APP_ENV;
    if (env === 'prod') {
      baseUrl = "https://ice.dpn.inmar.com/v2/offers/load";
    } else if (env === 'test') {
      baseUrl = "https://ice.test.dpn.inmar.com/v2/offers/load";
    } else {
      baseUrl = "https://ice.dpn.inmar.com/v2/offers/load";
    }
    

    const options = {
      headers: {
        'Content-Type': 'application/json',
        'X-Retailer': retailer.dpn_instance
      }
    };
    const phoneUrl = `${baseUrl}?username=${encodeURIComponent(phoneNumber)}&id=${encodeURIComponent(id)}&channel=Eventsite&usernameType=phone`;
    const emailUrl = `${baseUrl}?username=${encodeURIComponent(email)}&id=${encodeURIComponent(id)}&channel=Eventsite&usernameType=email`;

    const phoneResponse = await fetch(phoneUrl, options);
    const emailResponse = await fetch(emailUrl, options);

    const phoneResult = await phoneResponse.json();
    const emailResult = await emailResponse.json();

    return phoneResult.status !== 404 && emailResult.status !== 404;
  }

  function handleValidation() {
    if( terms_and_conditions_requirement==='True' && !checkbox){
      setLoginError('You must agree to the Terms and Conditions in order to enroll or sign in')
      return <div className="error text-danger">{loginError}</div>
    }
    checkUserExistence(qualifying_offer_id, phoneNumber, email).then(userExists => {
      if (phoneNumber && userExists && checkbox) {
        const options = {
          is_qualifying_offer: true,
          endpoint: 'offers/load',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-retailer': retailer.dpn_instance,
            Origin: window.location.origin,
          },
          body: { 
            username: phoneNumber, 
            captchaToken: "22",
            id: qualifying_offer_id,
            channel: channel, 
            funnel: funnel,
          },
          retailer: retailer.dpn_instance
        };

        dispatch(CLIP_OFFER(options)).then((resp) => {
          if (resp.payload.success) {
            setValidationSuccessful(true);
            dispatch(SET_USER({ state: 2, username: phoneNumber, enrollment: true }));
            saveToStorage(phoneNumber, JSON.stringify(resp.payload.mdid));
            handleClose();
            console.log(resp.payload.code)
            
            enrollEmail(email, false, resp.payload.code)
            .then(() => {
              console.log('Email enrollment complete');
            })
            .catch((error) => {
              console.log('Error enrolling email', error);
            });

          } else {
            setEnrollmentError(resp.payload.errorMsg);
            console.log(enrollmentError)
            setShowError(true);
          }
        });
      } else {
        setEnrollmentError('Phone number or email not found.');
        console.log(enrollmentError)
        setShowError(true);
        trackEnrollmentError();
      }
    })
  }

  function saveToStorage(username, mdid) {
    const obj = { username: username, myIds: mdid };
    localStorage.setItem('pbyt', JSON.stringify(obj));
  }

  function RenderLoginError() {
    if (loginError) {
      return <div className="error text-danger">{loginError}</div>;
    }
    return '';
  }

  function trackLoginEvent() {
    window.gtag('event', 'account_login', { retailer_id: retailer.dpn_instance,
    version: localStorage.getItem("AB_TEST_VARIABLE") || 'N/A'  });
    console.log("tracked login event");
  }

  function trackEnrollmentEvent() {
    console.log('Enrollment event tracked');
    window.gtag('event', 'enrollment', { retailer_id: retailer.dpn_instance,
    version: localStorage.getItem("AB_TEST_VARIABLE") || 'N/A' });
  }

  function trackEnrollmentError() {
    console.log('track enrollment error event');
    window.gtag('event', 'enroll_error', {  version: localStorage.getItem("AB_TEST_VARIABLE") || 'N/A'  });
  }

  if (!enrollment) {
    return (
      <>
      <div id='enrollment-container'>
        <div className='enrollment-offer-container' style={{ backgroundColor: block.color_background_1 }}>
          <h1 style={{ color: block.color_title }}>{block.enrollment_title}</h1>
        </div>
        <div className="login-container" style={{ backgroundColor: block.color_background_2 }}>
          <h2 style={{ color: block.color_header }}>{block.enrollment_header}</h2>
          <div className="enrollment-login-content-container">
            <form>
              <label htmlFor="phone" style={{ color: block.color_form_field_headers }}>{block.enroll_credential_1}<span>*</span></label>
              <input
                type="text"
                onChange={(e) => { checkPhoneNumberValidation(e); }}
                placeholder={block.enroll_credential_placeholder_1}
                />

              <label htmlFor="email" style={{ color: block.color_form_field_headers }}>{block.enroll_credential_2}<span>*</span></label>
              <input
                type="text"
                onChange={(e) => { checkEmailValidation(e); }}
                placeholder={block.enroll_credential_placeholder_2}
                />
                 {terms_and_conditions_requirement ==='True' &&( <div class="checkbox">
                   <Checkbox checked={checkbox} onChange={handleCheckBoxChange} />
                    <label style={{ color: block.color_form_field_headers }}>By enrolling in the program, you agree with the <span><a target="_blank" href={terms_and_conditions_Link}>Terms and Conditions</a></span>.</label>
                </div>)}

              <RenderLoginError />
            </form>
            <div id="enroll-or-sign-in-buttons-container">
              <button
                id='enroll-button'
                onClick={() => {
                  setEnrollmentButtonClicked(true);
                  handleValidation();
                }}
                style={{ color: block.color_enroll_button_text, backgroundColor: block.color_enroll_button_background }}
                >
                {block.enroll_button_text}
              </button>
              <button
                id='sign-in-button'
                onClick={handleValidation}
                style={{ backgroundColor: block.color_sign_in_button_background, color: block.color_sign_in_button_text }}
                >
                {block.sign_in_button_text}
              </button>
            </div>
            {showError && <ErrorModal show={showError} handleClose={handleErrorClose} page={page} retailer={retailer}/>}
            <div className='enrollment-footer'>
              <div style={{ color: block.color_form_field_headers }} dangerouslySetInnerHTML={{ __html: block.enrollment_below_form }} />
            </div>
          </div>
        </div>
        {showError && <ErrorModal show={showError} handleClose={handleErrorClose} page={page} retailer={retailer} />}
      </div>
      </>
    )
  }
  else {
    console.log("ProgressBlock");
    console.log(page);
    return  <ProgressBlock user={user} page={page} retailer={retailer}/>;
  }
}

export default LoyaltyBlock;
