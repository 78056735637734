import React from "react";
import Submission from "./cashbackSubmissionSearch/Submission";
import GridContainer from "./CashBackOffers";
import { shouldRenderComponent } from '../shared/util/ComponentAuth';
import "./Rebates.scss";

function Rebates(page) {
  let pageData = page.page;

  if (!shouldRenderComponent(pageData.auth_visibility)) {
    return null;
  }

  return (
    <>
    <div style={{ marginTop: '16px' }}>
      <GridContainer page={pageData} />
      <Submission page={pageData} />
    </div>
    </>
  );
}

export default Rebates;
