import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Webcam from 'react-webcam';
import { Button, Grid } from "@material-ui/core";
import CapturedImages from './CapturedImages';
import MobileSwipeableDrawer from './MobileSwipeableDrawer';
import { receiptUpload } from '../service/receiptUpload_service';
import { default as deleteMobileIcon } from "../assets/images/svg/deleteMobileIcon.svg";
import { default as plusMobileIcon } from "../assets/images/svg/plusMobileIcon.svg";
import { default as infoMobileIcon } from "../assets/images/svg/infoMobileIcon.svg";
import { default as cameraMobileIcon } from "../assets/images/svg/cameraMobileIcon.svg";
import { default as closeMobileIcon } from "../assets/images/svg/closeMobileIcon.svg";

function MobileView({ successMessage, open, handleClose, retailerConfig }) {

    // setting height and width as per device
    const screenWidth = window.innerWidth - 80
    const screenHeight = window.innerHeight - 200
    const [isShowVideo, setIsShowVideo] = useState(true);
    const [captureComplete, setCaptureComplete] = useState(false);
    const [capturedImages, setCapturedImages] = useState([]);
    const [currentImage, setCurrentImage] = useState('')
    const [retake, setRetake] = useState(false)
    const [currentIndex, setCurrentIndex] = useState()
    const [completed, setCompleted] = useState(false)
    const videoElement = useRef(null);
    const [openDrawer, setOpenDrawer] = useState(false)
    const[imageDelete, setImageDeleted] = useState(false)

    const videoConstraints = {
        width: screenWidth,
        height: screenHeight,
        facingMode: "environment"
        // below to used for front camera if req
        // facingMode: { exact: "user" }
    }
    const useStyles = makeStyles((theme) => ({
        paper: {
            fontFamily:'Switzer, sans-serif',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            backgroundColor: '#000',
            boxShadow: theme.shadows[5],
            //   padding: theme.spacing(0, 4, 3),
            padding: '20px 10px',
            height: '100%',
            width: '100%'
        },
        content: {
            fontFamily:'Switzer, sans-serif',
            display: 'flex',
        },
        camView: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'fit-content',
            border: '3px dashed #0054A6',
            borderRadius: '20px',
            marginTop: '16px',
            '& video':{
                objectFit: 'cover',
            }
        },
        infoDiv: {
            fontFamily:'Switzer, sans-serif',
            minHeight: '30px',
            width: '100%',
            justifyContent: "space-between",
            display: 'flex',
            padding: '0px 32px',
        },
        infoText: {
            fontFamily:'Switzer, sans-serif',
            fontSize: '12px',
            color: '#fff',
            fontWeight: '400',
            position: 'relative',
            bottom: '10px',
            padding: '0px 12px',
            borderRadius: '240px',
            background: 'rgba(0, 0, 0, 0.40)',
            backdropFilter: 'blur(6.400000095367432px)',
        },
        closeButton: {
            position: 'relative',
            alignSelf: 'flex-start',
            maxHeight: '48px',
            maxWidth: '115px',
            marginTop: 'auto',
            marginBottom: 'auto'
        },
        actionDiv: {
            display: 'flex',
            position: 'relative',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            padding: '0px 34px',
            textAlign: 'center'
        },
        retakeButton: {
            fontFamily:'Switzer, sans-serif',
            borderRadius: '240px',
            background: '#FFF',
            padding: '16px 24px',
            fontWeight: 700,
            fontSize: '16px',
            color: '#25272C',
            maxWidth: '115px',
            maxHeight: '48px',
            boxShadow: '0px 38px 11px 0px rgba(29, 60, 109, 0.00), 0px 24px 10px 0px rgba(29, 60, 109, 0.01), 0px 14px 8px 0px rgba(29, 60, 109, 0.05), 0px 6px 6px 0px rgba(29, 60, 109, 0.09), 0px 2px 3px 0px rgba(29, 60, 109, 0.10), 0px 0px 0px 0px rgba(29, 60, 109, 0.10)',
        },
        nextButton: {
            fontFamily:'Switzer, sans-serif',
            borderRadius: '240px',
            background: '#0073C5',
            padding: '16px 24px',
            fontWeight: 700,
            fontSize: '16px',
            color: '#FFF',
            maxHeight: '48px',
            maxWidth: '115px',
        },
        completedContainer: {
            fontFamily:'Switzer, sans-serif',
            textAlign: 'center',
            backgroundColor: '#fff',
            height: '100%',
            width: '100%'
        },
        titleText: {
            fontFamily:'Switzer, sans-serif',
            paddingTop: '50%',
            fontSize: '28px',
            fontWeight: 900,
            color: '#25272C'
        },
        submissionInfoText: {
            marginTop: '16px',
            fontSize: '16px',
            fontWeight: 400,
            color: '#25272C'
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        doneButton: {
            fontFamily:'Switzer, sans-serif',
            backgroundColor: '#0073C5',
            borderRadius: '240px',
            display: 'flex',
            justifyContent: 'center',
            color: '#FFF',
            fontSize: '16px',
            fontWeight: 700,
            border: '1px solid #0073C5',
            width: '90%',
            padding: '16px 32px',
            marginTop: '64px'

        },
        reOpenButton: {
            fontFamily:'Switzer, sans-serif',
            backgroundColor: '#FFF',
            borderRadius: '240px',
            display: 'flex',
            justifyContent: 'center',
            color: '#0073C5',
            fontSize: '16px',
            fontWeight: 700,
            border: '1px solid #0073C5',
            width: '90%',
            padding: '16px 32px',
            marginTop: '16px'
        },


    }));
    const capture = React.useCallback(() => {
        const imageSrc = videoElement.current.getScreenshot();
        return imageSrc
    }, [videoElement]);

    const handleCloseModal = () => {
        // handling close modal
        // clearing state
        setCapturedImages([])
        setCaptureComplete(false)
        setRetake(false)
        setCompleted(false)
        // closing modal
        handleClose();
    }

    const setImages = (imgSrc) => {
        // adding captured images to the state
        const data = imgSrc;
        capturedImages.push(data);
        setCapturedImages([...capturedImages]);
    };

    const handleCapture = () => {
        // to capture image
        const imageSrc = capture()
        if (capturedImages.length < 10) {
            setImages(imageSrc);
        }
    }
    const handleNext = () => {
        // to handle next button click
        setCaptureComplete(true)
    }
    const handleSubmit = async () => {
        // to handle submit
        const retailer = retailerConfig.dpn_instance.dpn_instance
        const response  = await receiptUpload(capturedImages, retailer)
        setCaptureComplete(false)
        setCapturedImages([])
        setCompleted(true)
    }

    const selectedImage = (item) => {
        // to handle photo click
        setRetake(false)
        setCurrentImage(item)
    }
    const handleRetake = () => {
        // to handle retake
        setRetake(true)
    }
    const handleSetCurrentIndex = (index) => {
        // to set current photo index
        setCurrentIndex(index)
    }
    const handleRetakePhoto = () => {
        // to capture selected photo again
        const imageSrc = capture()
        capturedImages[currentIndex] = imageSrc;
        setCapturedImages([...capturedImages]);
    }
    const handleDeleteImage = () => {
        const images = capturedImages.splice(currentIndex, 1)
        setCurrentImage(images);
        setImageDeleted(true)
    }

    // handle toggle for information text
    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenDrawer(open);
    };


    const classes = useStyles();

    // cameraActiveBody appear when camera action is ongoing
    const cameraActiveBody = (
        <Grid className={classes.paper}>
            <Grid item className={classes.infoDiv}>
                <Button className={classes.closeButton} onClick={handleCloseModal}>
                    <img src={closeMobileIcon}></img>
                </Button>
                <Button className={classes.infoButton} onClick={toggleDrawer(true)}>
                    <img src={infoMobileIcon}></img>
                </Button>
            </Grid>

            {<Grid item className={classes.camView} style={{ position: 'relative' }}>
                <p className={classes.infoText} style={{ margin: '0px', padding: '0px', rotate: '-90deg', bottom: '50%', position: 'absolute', left: '-56px' }}>Receipt Edge</p>
                {isShowVideo &&
                    <Webcam style={{ borderRadius: '20px', height:`${screenHeight}px`, width:`${screenWidth}px` }} audio={false} ref={videoElement} videoConstraints={videoConstraints} screenshotFormat="image/png" />
                }
                <p className={classes.infoText} style={{ margin: '0px', padding: '0px', rotate: '-90deg', bottom: '50%', position: 'absolute', right: '-56px' }}>Receipt Edge</p>
            </Grid>}
            <p className={classes.infoText}>If the receipt is too long, take multiple photos</p>

            <Grid item className={classes.actionDiv}>
                {/* last captured image */}
                {<Grid item xs={4}>
                   {capturedImages.length>=1 && ( <img className={classes.capturedImage} width={60} height={70} src={capturedImages[capturedImages.length - 1]}></img>)}
                </Grid>}

                {/* captured button */}
                <Grid item xs={4}>
                    <Button onClick={handleCapture}>
                        <img src={cameraMobileIcon}></img>
                    </Button>
                </Grid>
               <Grid item xs={4}>
                   {capturedImages.length>=1 && ( <Button onClick={handleNext} className={classes.nextButton}>
                        Next
                    </Button>)}
                </Grid>

            </Grid>
        </Grid>

    );

    // cameraDeactivebody will appear when camera action is completed
    const cameraDeactivebody = (
        <Grid className={classes.paper}>
            <div className={classes.infoDiv}>
                <Button className={classes.closeButton} onClick={handleCloseModal}>
                    <img src={closeMobileIcon}></img>
                </Button>
                {/* display info div */}
                {currentImage !== '' && retake && <Button className={classes.infoButton} onClick={handleClose}>
                    <img src={infoMobileIcon}></img>
                </Button>}
                {currentImage !== '' && !retake && <Button onClick={handleDeleteImage}>
                    <img src={deleteMobileIcon}></img>
                </Button>}
            </div>


            <Grid className={classes.camView}>
                {/* {!retake && <img style={{ borderRadius: '20px' }} className={classes.capturedImage} width={screenWidth} height={screenHeight - 60} src={currentImage || capturedImages[capturedImages.length - 1]}></img>
                } */}
                 {!retake && <img style={{ borderRadius: '20px' }} className={classes.capturedImage} width="auto" height="auto" src={currentImage || capturedImages[capturedImages.length - 1]}></img>
                }
                {retake &&
                    <Webcam style={{ borderRadius: '20px' }} audio={false} ref={videoElement} videoConstraints={videoConstraints} screenshotFormat="image/png" />
                }
            </Grid>

            {/* displaying all captured images */}
            {currentIndex >= 0 && <p className={classes.infoText} style={{ margin: '0px' }}>{currentIndex + 1} of {capturedImages.length} </p>}

            <CapturedImages setImageDeleted={setImageDeleted} imageDelete={imageDelete} capturedImages={capturedImages} selectedImage={selectedImage} currentIndex={currentIndex} handleSetCurrentIndex={handleSetCurrentIndex}></CapturedImages>

            <Grid className={classes.actionDiv}>
                {/* last retake image */}
                <Grid item>
                    <Button onClick={!retake ? handleRetake : handleRetakePhoto} className={classes.retakeButton}>
                        Retake
                    </Button>
                </Grid>

                {/* captured button */}
                {captureComplete && <Grid item>
                    <Button onClick={() => setCaptureComplete(false)}>
                        <img src={plusMobileIcon}></img>
                    </Button>
                </Grid>}
                <Grid item>
                    <Button onClick={handleSubmit} className={classes.nextButton}>
                        Submit
                    </Button>
                </Grid>

            </Grid>

        </Grid>

    );

    const submissionBody = (
        <Grid className={classes.completedContainer}>
            <Grid item xs={12} className={classes.titleText}>
                Thanks!
            </Grid>
            <Grid item xs={12}>
                <p className={classes.submissionInfoText}>
                    {successMessage}
                </p>
            </Grid>
            <Grid item xs={12} className={classes.buttonContainer}>
                <Button className={classes.doneButton} onClick={() => handleCloseModal()}>Done</Button>
            </Grid>
            <Grid item xs={12} className={classes.buttonContainer}>
                <Button className={classes.reOpenButton} onClick={() => handleCloseModal()}>Upload Another</Button>
            </Grid>
        </Grid>
    )

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {completed ? submissionBody : (captureComplete && capturedImages.length!==0) ? cameraDeactivebody : cameraActiveBody}

            </Modal>
            <MobileSwipeableDrawer className={classes.drawer} toggleDrawer={toggleDrawer} open={openDrawer}></MobileSwipeableDrawer>

        </div>
    );
}

export default MobileView