import React from "react";
import { Tab, Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const MatTabs = withStyles((theme) => ({
  root: {
    padding: "5px",
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "4px",
    "& > span": {
      width: "100%",
      top: "0px",
    },
  },
}))((props) => (
  <Tabs
    style={{ width: "100%", alignItems: "baseline" }}
    variant="fullWidth"
    alignItems="left"
    {...props}
    TabIndicatorProps={{
      children: <span />,
    }}
  />
));

export const MatTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: 2,
    minHeight: theme.spacing(5),
    color: "#5A6D81",
    "&:focus": {
      opacity: 1,
    },
  },
  selected: {
    display: "flex !important",
    color: "#212931",
    background: "#d3deea",
    borderLeft: "4px solid #0062cc",
    borderRadius: "4px",
    borderRadius: "0",
    outline: "none !important",
    fontSize: "16px",
    fontFamily: "Interbold",
    "&:hover": {
      background: "#d3deea",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
  },
}))((props) => <Tab {...props} />);

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ width: "100%" }}
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

export function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
    className: "accountWrapper",
  };
}
