import React from 'react';
import { Waypoint } from 'react-waypoint';

function WaypointTriggered(props) {
  const { page, totalPages, setPage } = props;
  const loadMoreItems = () => {
    // getting total pages to set the page on item load
    if (totalPages) {
      if (totalPages > page) {
        setPage(page + 1);
      } else {
        setPage(1);
      }
    } else {
      setPage(page + 1);
    }
  };

  return (
    <span style={{ height: '30px' }}>
      {'\u00A0'}
      <Waypoint onEnter={() => loadMoreItems()} />
    </span>
  );
}

export default WaypointTriggered;
