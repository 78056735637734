import { SET_USER_LOGIN_STATUS, SET_LOYALITY_POINTS, USER_DETAILS } from "../reducers/user";
import { SET_RETAILER_CONFIG } from '../reducers/config';
import { getUserPoints }  from "../../service/account_service";

const accountActions = {
  updateUserLytPoints(retailer) {
    return async (dispatch) => {
      try {
        const cashbackBalances = await getUserPoints(retailer);
        
        const totalPoints = cashbackBalances?.reduce((sum, item) => {
          if (item.redemptionType === "external" || item.redemptionType === "unrestricted") {
            return sum + item.balance;
          }
          return sum;
        }, 0);

        dispatch(SET_LOYALITY_POINTS({ points: totalPoints }));
      } catch (error) {
        console.error('Error in updateUserLytPoints:', error);
      }
    };
  },

  setUserLoginStatus() {
    return async (dispatch) => {
      dispatch(SET_USER_LOGIN_STATUS());
    };
  },

  setUserDetails(data) {
    return async (dispatch) => {
      dispatch(USER_DETAILS(data));
    };
  },

  serRetailerConfig(config) {
    return async (dispatch) => {
      dispatch(SET_RETAILER_CONFIG(config));
    };
  }
};

export default accountActions;
