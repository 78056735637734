import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Grid, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import loyalityOffersActions from "../../redux/actions/loyalityOffersActions";
import LoyalityOffer from "./LoyalityOffer";
import { enrollProgram } from "../../service/myloyalty_services";
import WaypointTriggered from "../../shared/util/waypoint";

export function LoyalityProgramGrid(props) {
  const lpGridBlock = props.page.components.find(
    (component) => component.block_type === "LoyaltyProgramGridBlock"
  );

  const retailerConfig = useSelector((state) => state.config.retailerConfig);
  const loyalityPrograms = useSelector((state) => state.loyalityPrograms);
  const { isLogin } = useSelector((state) => state.user);

  const [page, setPage] = useState(1);

  const totalPages = loyalityPrograms.programs.totalPages;

  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    pageSubTitle: {
      fontSize: "2rem",
      fontWeight: "600",
      margin: "24px 0",
      letterSpacing: "1px",

      [theme.breakpoints.between("xs", "sm")]: {
        margin: "18px 0",
      },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    dispatch(
      loyalityOffersActions.getLoyalityOffers(
        retailerConfig.dpn_instance.dpn_instance,
        isLogin ? ["clipped", "available"] : ["available"],
        page
      )
    );
  }, []);

  useEffect(() => {
    if (page <= totalPages && page !== 1) {
      dispatch(
        loyalityOffersActions.getLoyalityOffers(
          retailerConfig.dpn_instance.dpn_instance,
          isLogin ? ["clipped", "available"] : ["available"],
          page
        )
      );
    }
  }, [page]);

  const onEnrollProgramHandler = async (id) => {
    const response = await enrollProgram(
      retailerConfig.dpn_instance.dpn_instance,
      id
    );
    if (!response.error) {
      if (response.result) {
        dispatch(loyalityOffersActions.enrollProgramSuccess(id));
      }
    } else {
      setError("Error");
    }
  };

  return (
    <div>
      <Container>
        <Grid container>
          {/* In Progress offers section */}
          {isLogin && (
            <Grid item md={12} xs={12}>
              <div className={classes.pageSubTitle}>
                {lpGridBlock?.inProgressOffersText}
              </div>
            </Grid>
          )}

          {isLogin && (
            <main style={{ width: "100%" }} tabIndex="-1">
              <Grid
                container
                direction="row"
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                justifyContent="flex-start"
              >
                {loyalityPrograms.programs.clippedPrograms &&
                  loyalityPrograms.programs.clippedPrograms.map(
                    (program, idx) => (
                      <Grid
                        container
                        direction="row"
                        item
                        xs={6}
                        sm={6}
                        md={3}
                        lg={3}
                        spacing={1}
                        justifyContent="flex-start"
                        alignItems="stretch"
                        style={{
                          borderRight: "1px solid #CCD1D7",
                          borderBottom: "1px solid #CCD1D7",
                        }}
                      >
                        <LoyalityOffer
                          program={program}
                          retailerConfig={retailerConfig}
                          id={`loyality-program-${idx}`}
                        />
                      </Grid>
                    )
                  )}
              </Grid>
            </main>
          )}

          {/* Avilable offers section */}
          <Grid item md={12} xs={12}>
            <div className={classes.pageSubTitle}>
              {lpGridBlock?.availableOffersText}
            </div>
          </Grid>

          <main style={{ width: "100%" }} tabIndex="-1">
            <Grid
              container
              direction="row"
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              justifyContent="flex-start"
            >
              {loyalityPrograms.programs.avilablePrograms &&
                loyalityPrograms.programs.avilablePrograms.map(
                  (program, idx) => (
                    <Grid
                      container
                      direction="row"
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      lg={3}
                      spacing={1}
                      justifyContent="flex-start"
                      alignItems="stretch"
                      id={`avilable-loy-program-${idx}`}
                      style={{
                        borderRight: "1px solid #CCD1D7",
                        borderBottom: "1px solid #CCD1D7",
                      }}
                    >
                      <LoyalityOffer
                        program={program}
                        retailerConfig={retailerConfig}
                        enrollCurrentProgram={(id) =>
                          onEnrollProgramHandler(id)
                        }
                      />
                    </Grid>
                  )
                )}
            </Grid>
          </main>
        </Grid>

        {/* Infinite scroll trigger */}
        {page !== totalPages &&
          (loyalityPrograms.programs.avilablePrograms.length > 0 ||
            loyalityPrograms.programs.clippedPrograms.length > 0) && (
            <Grid>
              <WaypointTriggered
                page={page}
                totalPages={totalPages}
                setPage={(p) => setPage(p)}
              />
            </Grid>
          )}

        {page !== totalPages && (
          <Grid
            item
            xs={12}
            md={12}
            sm={12}
            lg={12}
            alignItems="center"
            justifyContent="center"
            container
          >
            <CircularProgress size={60} style={{ marginTop: "24px" }} />
          </Grid>
        )}
      </Container>
    </div>
  );
}

export default LoyalityProgramGrid;
