import { getLoyalityPrograms } from "../../service/myloyalty_services";
import constants from './constans';

const loyalityOffersActions = {
  getLoyalityOffers(retailer, groups, page) {
    return async (dispatch) => {
      const programs = await getLoyalityPrograms(localStorage.getItem('token'), retailer, groups ? groups : null, page ? page : 1);
      dispatch(loyalityOffersActions.setLoyalityOffers(programs));
    };
  },

  setLoyalityOffers(data) {
    return {
      type: constants.SET_LOYALITY_OFFERS,
      data,
    };
  },

  enrollProgramSuccess(data) {
    return {
      type: constants.SET_ENROLL_PROGRAM_SUCCESS,
      data,
    };
  },
};

export default loyalityOffersActions;
