import React from 'react';
import CartContentItem from './item';
import './content.scss';
import { shouldRenderComponent } from '../shared/util/ComponentAuth';

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

function CartContent({ component }) {
  const data = [...component.addtocart_set];
  let a2cHeader = component.a2c_header;
  let a2cSubtitle = component.a2c_subtitle_text;
  let randomizeArray = component.randomize_order;
  let headerTextColor = component.header_text_color;

  if (data && data.length > 1 && randomizeArray) {
    var result = shuffle([...data])
  }
  else {
    var result = data;
  }

  if (result.a2cHeader) {
    a2cHeader = result.a2cHeader;
  }
  if (!shouldRenderComponent(component.auth_visibility)) {
    return null;
  }
  return (
    <>
      {result.length > 0 && (
        <div className="content-page">
          <div className="tw-w-full tw-max-w-[1440px] tw-mx-auto tw-px-4 tw-py-8 tw-flex tw-flex-col tw-justify-start tw-items-center tw-gap-4">
            {a2cHeader && <p className={`tw-text-center tw-text-zinc-800 tw-text-4xl tw-font-extrabold tw-font-['Switzer Variable'] tw-leading-tight sm:tw-text-5xl md:tw-text-6xl lg:tw-text-7xl ${a2cSubtitle ? 'noBottomPadding' : ''}`} style={{ color: headerTextColor }}>{a2cHeader}</p>}
            {a2cSubtitle && <p className="w-full max-w-[680px] text-center text-zinc-800 text-base font-normal font-['Switzer Variable'] leading-snug sm:text-lg md:text-xl" style={{ color: headerTextColor }}>{a2cSubtitle}</p>}
          </div>
          <div className="cart-content">
            {result.map((item, i) => {
              return <CartContentItem component={component} item={item} key={i} />;
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default CartContent;
