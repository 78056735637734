import axios from 'axios';
import { getValidToken, makeAuthenticatedRequest } from '../shared/util/rebatesAuthHelper';

export async function getToken(){
    const myHeaders = new Headers();
    const raw = JSON.stringify({
      "action": "get_token"
    });
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    const data =   await fetch(process.env.REACT_APP_REBATE_SUBMISSION_LAMBDA, requestOptions)
    const result  = await data.json()
    return result
}

export async function getOffers(clientNumber) {
  try {
    const token = await getValidToken();
    console.log("getOffers: Valid token obtained");
    let config = {
      method: 'get',
      url: `${process.env.REACT_APP_REBATES_API}/api/Offer/getOffersByClientNumber`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'RebatesClientIdentifierKey': clientNumber
      }
    };

    const result = await makeAuthenticatedRequest(config);
    return result;
  } catch (error) {
    console.error("Error in getOffers:", error);
    throw error;
  }
}

export async function getOfferSummaryByOfferCode(clientNumber, offerCode) {
  try {
    const token = await getValidToken();
    let config = {
      method: 'post',
      url: `${process.env.REACT_APP_REBATES_API}/api/Offer/getOffer`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'RebatesClientIdentifierKey': clientNumber,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        offerCode: offerCode
      })
    };
    const result = await makeAuthenticatedRequest(config);
    return result;
  } catch (error) {
    console.error("Error in getOfferSummaryByOfferCode:", error);
    throw error;
  }
}

export function submissionSearch(submissionNumber, emailAddress, clientNumber) {
    let data = {
        "submissionNumber": submissionNumber,
        "emailAddress": emailAddress,
        "phoneNumber": "",
        "firstName": "",
        "lastName": "",
        "zipCode": "",
        "submissionDate": ""
    };
    
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_REBATE_TRACKING_URL,
        headers: { 
            'Content-Type': 'application/json', 
            'RebatesClientIdentifierKey': clientNumber 
        },
        data: data
    };

    return makeAuthenticatedRequest(config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.error(error);
        });
}

