import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Grid,
  makeStyles,
  useMediaQuery,
  createTheme,
} from "@material-ui/core";
import accountActions from "../redux/actions/accountActions";
import StarIcon from "../assets/images/svg/star.svg";
import { shouldRenderComponent } from "../shared/util/ComponentAuth";
import { formatLoyaltyPoints } from "../shared/util/loyaltyHelper";

function LoyaltyHeader({ props, site_Config }) {
  const retailerConfig = useSelector((state) => state.config.retailerConfig);
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const user = useSelector((state) => state.user);
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(user?.userDetails);

  const headerBlockConfig = props.components.find(
    (component) => component.block_type === "HeaderBlock"
  );
  const headerConfig = headerBlockConfig.header_set[0];
  const authHeader = headerBlockConfig.auth_visibility;
  const pointsUnit = site_Config?.loyaltyPoints_display ?? 'POINTS';

  useEffect(() => {
    if (!user.points) {
      dispatch(accountActions.updateUserLytPoints(retailerConfig.retailer));
    }
    setUserInfo(JSON.parse(localStorage.getItem("userData")));
  }, []);

  const useStyles = makeStyles((theme) => ({
    container: {
      backgroundImage: `url(" ${
        isMobile
          ? headerConfig?.mobile_header
          : headerConfig?.desktop_header
      }")`,
      backgroundPosition: "bottom",
      display: "flex",
      justifyContent: `${headerConfig.header_position}`,
      alignItems: `${headerConfig.header_position}`,
      height: `${headerConfig.header_heigth}`,
    },
    loginLogo: {
      width: "240px",
      height: "auto",
      margin: "1rem 4rem",
      objectFit: "contain",
      // Mobile & mini ipad < 600px
      [theme.breakpoints.down("xs")]: {
        width: "180px",
      },
    },
    program_text: {
      fontSize: "24px",
      fontWeight: 400,
      lineHeight: "38.4px",
      textAlign: "center",
      fontFamily: site_Config?.font_family ? site_Config?.font_family : "Inter",
      color: `${headerConfig.freeblock_text_color}`,
    },
    signUpBtn_text: {
      color: `${headerConfig.button_text_color}`,
      background: `${headerConfig.button_color}`,
      outline: "none",
      padding: "1rem 1.6rem",
      fontSize: "16px",
      fontFamily: site_Config?.font_family
        ? site_Config?.font_family
        : "InterBold",
      width: "160px",
      maxWidth: "160px",
      boxShadow: "none",
      borderRadius: "20px",
      lineHeight: 1,
      textTransform: "capitalize",
      marginTop: "14px",

      "&:hover": {
        boxShadow: "none",
        backgroundColor: `${headerConfig.button_color}`,
      },
      "&:focus": {
        boxShadow: "none",
        backgroundColor: `${headerConfig.button_color}`,
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },

      [theme.breakpoints.down("xs")]: {
        width: "100%",
        padding: "1.2rem 1.6rem",
      },
    },
    accountName: {
      color: "#fff",
      fontSize: "24px",
      textAlign: "left",
    },
    accountInfo: {
      borderRadius: "30px",
      padding: "10px",
      background: "#fff",
      color: "#0054A6",
      width: "300px",
      height: user.points > 0 ? "" : "100px",
    },
    starImage: {
      float: "left",
      marginTop: "35px",
      marginLeft: "14px",
    },
    accntPoints: {
      float: "left",
      textAlign: "left",
      marginLeft: "28px",
      marginTop: "14px",
    },
    redeembtn: {
      color: `${headerConfig.button_text_color}`,
      background: `${headerConfig.button_color}`,
      outline: "none",
      padding: "0.6rem 0.6rem",
      fontSize: "16px",
      fontFamily: site_Config?.font_family
        ? site_Config?.font_family
        : "InterBold",
      width: "110px",
      maxWidth: "160px",
      boxShadow: "none",
      borderRadius: "20px",
      lineHeight: 1,
      textTransform: "capitalize",
      marginTop: "14px",

      "&:hover": {
        boxShadow: "none",
        backgroundColor: `${headerConfig.button_color}`,
      },
      "&:focus": {
        boxShadow: "none",
        backgroundColor: `${headerConfig.button_color}`,
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },

      [theme.breakpoints.down("xs")]: {
        width: "100%",
        padding: "1.2rem 1.6rem",
      },
    },
  }));

  const classes = useStyles();
  if (!shouldRenderComponent(authHeader)) {
    return null;
  }
  return (
    <>
      {(authHeader === "authenticated" || authHeader === "both") && token && (
        <Grid
          item
          xs={12}
          className={classes.container}
          style={{ textAlign: `${headerConfig.header_position}` }}
        >
          <div
            style={{
              fontFamily: site_Config?.font_family
                ? site_Config?.font_family
                : "InterBold",
            }}
          >
            <div className={classes.accountName}>
              Hi,{" "}
              {userInfo !== undefined && userInfo !== null
                ? userInfo?.firstName
                : user?.userDetails?.firstName}
            </div>
            <div className={classes.accountInfo}>
              <img className={classes.starImage} src={StarIcon} alt="star" />
              <div className={classes.accntPoints}>
                <h4>{formatLoyaltyPoints(user.points, pointsUnit)}</h4>
                <h6>{pointsUnit === 'DOLLARS' ? 'Earnings available' : 'Points available'}</h6>
              </div>
              {user.points > 0 && (
                <Button variant="contained" className={classes.redeembtn}>
                  <span>Redeem</span>
                </Button>
              )}
            </div>
          </div>
        </Grid>
      )}
      {(authHeader === "unauthenticated" || authHeader === "both") &&
        !token && (
          <Grid
            item
            xs={12}
            className={classes.container}
            style={{ textAlign: `${headerConfig.header_position}` }}
          >
            <div style={{ maxWidth: "720px" }}>
              <img
                className={classes.loginLogo}
                alt={
                  headerConfig?.logo_alt_text
                    ? headerConfig?.logo_alt_text
                    : "logo"
                }
                src={headerConfig.logo}
              />
              <p className={classes.program_text}>
                {headerConfig.freeblock_text}
              </p>
              <Button
                onClick={() =>
                  navigate(`/${site_Config.brandsite_name}/signup`)
                }
                variant="contained"
                className={classes.signUpBtn_text}
              >
                <span style={{ marginRight: "10px" }}>
                  {headerConfig.button_text}
                </span>
                <svg
                  width="24"
                  height="21"
                  viewBox="0 0 24 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.0977 11.5034L14.6602 19.9409C14.0273 20.6265 12.9199 20.6265 12.2871 19.9409C11.6016 19.3081 11.6016 18.2007 12.2871 17.5679L17.8242 11.978H1.6875C0.738281 11.978 0 11.2397 0 10.2905C0 9.39404 0.738281 8.60303 1.6875 8.60303H17.8242L12.2871 3.06592C11.6016 2.43311 11.6016 1.32568 12.2871 0.692871C12.9199 0.00732422 14.0273 0.00732422 14.6602 0.692871L23.0977 9.13037C23.7832 9.76318 23.7832 10.8706 23.0977 11.5034Z"
                    fill={headerConfig.button_text_color}
                  />
                </svg>
              </Button>
            </div>
          </Grid>
        )}
    </>
  );
}
export default LoyaltyHeader;
