import React from 'react';
import ReactHtmlParser from 'html-react-parser';
import './content.scss';

function CartContentItem({ component, item }) {
  const img_path = `${item.product_image}`;

  function trackAddToCartEvent() {
    window.gtag('event', 'addtocartclick', {
      product: item.product_name,
      version: localStorage.getItem("AB_TEST_VARIABLE"),
    });
  }

  const buttonTextColor = component.a2c_button_text_color;
  const buttonBackgroundColor = component.a2c_button_background_color;

  const styles = {
    borderRadius: component.corner_radius === "round" ? '24px' : '0',
  };

  return (
    <div className="cart-card">
      <div className='card-item' style={styles}>
        <img src={img_path} className="card-img-top content-img" alt={item.alt_text || ''} />
        <div className="card-title h6">{ReactHtmlParser(item.product_name)}</div>
        <button className="cart-btn" style={{ backgroundColor: buttonBackgroundColor }}>
          <a
            href={item.url}
            onClick={trackAddToCartEvent}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: buttonTextColor }}
          >Add to Cart</a>
        </button>
      </div>
    </div>
  );
}

export default CartContentItem;
