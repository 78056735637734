import React from 'react';
import './tiktok.scss';
import { shouldRenderComponent } from '../shared/util/ComponentAuth';

function TikTokSection({ component }) {
    const tikTokBlock = component;
    let tiktokHeader = component.tiktok_header;
    let headerTextColor = component.header_text_color;

if (!shouldRenderComponent(component.auth_visibility)) {
    return null;
    }

    return (
        <>
            {tiktokHeader && tiktokHeader.trim() !== '' && (
                <div className="tw-w-full tw-max-w-[1440px] tw-mx-auto tw-px-4 tw-py-8 tw-flex tw-flex-col tw-justify-start tw-items-center tw-gap-4">
                <p className={`tw-text-center tw-text-zinc-800 tw-text-4xl tw-font-extrabold tw-font-['Switzer Variable'] tw-leading-tight sm:tw-text-5xl md:tw-text-6xl lg:tw-text-7xl ${tiktokHeader ? 'noBottomPadding' : ''}`} style={{ color: headerTextColor }}>
                    {tiktokHeader}
                </p>
                </div>
            )}
            <div className="content-tiktok">
                {tikTokBlock.tiktokpost_set.map((item, i) => (
                    <div key={i} className="card content-item">
                        <div className="parent">
                            <iframe src={`https://www.tiktok.com/embed/${item.tiktok_id}`} allowFullScreen scrolling="no" allow="encrypted-media"></iframe>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default TikTokSection;