import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  useMediaQuery,
  createTheme,
} from "@material-ui/core";
import FormWrapper from "../blocks/Account/FormWrapper";

function SignupWrapper(props) {
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const useStyles = makeStyles((theme) => ({
    paper: {
      maxWidth: 630,
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(5),
      boxShadow: "none",
    },
    paperMobile: {
      maxWidth: 560,
      margin: `${theme.spacing(1)}px auto`,
      padding: "28px 2px 10px 2px",
      boxShadow: "none",
    },
    loginLogo: {
      width: isMobile ? "100%" : "240px",
      height: "auto",
      margin: isMobile ? "" : "1rem 4rem",
      objectFit: "contain",
    },
    signIntxt: {
      fontSize: "32px",
      fontFamily: "InterMedium",
      fontWeight: "900",
    },
    createAccount: {
      padding: "10px",
      fontSize: "16px",
      fontFamily: "InterMedium",
    },
    createTxt: {
      color: props.page.color_background,
      cursor: "pointer",
      fontFamily: "InterMedium",
    },
  }));

  const classes = useStyles();

  const signupConfig = props.page.components.find(
    (component) => component.block_type === "SignupBlock"
  );
  const navigate = useNavigate();
  const formFields = signupConfig.retailerFields?.formFields;
  if (!signupConfig) {
    return <>Loading...</>;
  }
  const singupConfirmationText =
    signupConfig.singup_confirmation_text.replaceAll(
      "<CPG name>",
      props.page.dpn_instance.dpn_instance
    );


  return (
    <div className="login-page">
      <Grid item xs={12} zeroMinWidth style={{textAlign:signupConfig?.logo_Alignment}}>
        <a onClick={() => navigate(`/${props.page.brandsite_name}`)}>
          <img
            tabIndex={0}
            className={classes.loginLogo}
            alt="logo"
            src={signupConfig.logo}
          />
        </a>
      </Grid>

      <Paper className={isMobile ? classes.paperMobile : classes.paper}>
        <Grid container wrap="nowrap" spacing={1}>
          <Grid item xs={12} zeroMinWidth style={{ textAlign: "center" }}>
            <Typography
              tabIndex={0}
              className={classes.signIntxt}
              noWrap
              data-testid="signIn"
            >
              {signupConfig.page_title}
            </Typography>

            <Typography
              tabIndex={0}
              className={classes.createAccount}
              data-testid="donthaveAccount"
            >
              {signupConfig.signup_sub_titleText}
            </Typography>

            <FormWrapper
              formType="register"
              config={signupConfig}
              brandsiteName={props.page.brandsite_name}
              confirmTxt={singupConfirmationText}
              fields={formFields}
              retailerName={props.page.dpn_instance.dpn_instance}
            />

            <Typography
              tabIndex={0}
              onClick={() => navigate(`/${props.page.brandsite_name}/login`)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  navigate(`/${props.page.brandsite_name}/login`);
                }
              }}
              className={classes.createAccount}
              noWrap
              data-testid="donthaveAccount"
            >
              Already have an account?
              <span className={classes.createTxt} data-testid="createAccount">
                {" "}
                Log In
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default SignupWrapper;
