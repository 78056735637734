import constants from "../actions/constans";

const initialState = {
  programs: {
    avilablePrograms: [],
    clippedPrograms: [],
    page: 0,
    totalPages: 1,
  },
  selProgram: {},
  enrolledProgram: {},
};

export default function loyalityOffersReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_LOYALITY_OFFERS:
      let offers = { ...state.programs };

      let actOffers = [];
      let clippedOffers = [];

      if (action.data && action.data.items && action.data.items.length > 0) {
        for (let offer of action.data.items) {
          if (offer.status === "available") {
            actOffers.push(offer);
          } else {
            clippedOffers.push(offer);
          }
        }
      }

      if (offers.page === 0 || offers.page < action.data.page) {
        offers.avilablePrograms = [...offers.avilablePrograms, ...actOffers];
        offers.clippedPrograms = [...offers.clippedPrograms, ...clippedOffers];
        offers.page = action.data.page;
        offers.totalPages = action.data.pages;
      }

      return { ...state, ...{ programs: offers } };

    case constants.SET_ENROLL_PROGRAM_SUCCESS:
      let programs = { ...state.programs };

      let avilPrograms = programs.avilablePrograms.map((program) => {
        if (program.id === action.data) {
          // modify status of program on enroll success
          return {
            ...program,
            status: "clipped",
          };
        }
        return program;
      });

      programs.avilablePrograms = [...avilPrograms];

      return { ...state, ...{ programs: programs } };

    default:
      return state;
  }
}
