import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Grid,
  Paper,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import accountActions from "../redux/actions/accountActions";
import { formStyles } from "../styles/cpg/form_styles";
import { login, getUserInfo } from "../service/account_service";
import { isLoggedIn } from "../shared/util/ComponentAuth";
import { getFullPath } from "../config/retailerConfig";
import {
  validateUserName,
  validatePassword,
} from "../shared/util/UserValidations";

function LoginWrapper(props) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn()) {
      const params = new URLSearchParams(location.search);
      const focusParam = params.get('focus');
      
      const homeUrl = getFullPath('/home');
      const redirectUrl = focusParam ? `${homeUrl}?focus=${focusParam}` : homeUrl;
      
      navigate(redirectUrl);
    }
  }, []);

  const component = props.page.components.filter((item)=>item.block_type==='LoginBlock')[0]
  const {primary_color, text_color, logo_Alignment} = component
  const loginConfig = props.page.components.find(
    (component) => component.block_type === "LoginBlock"
  );
  const [values, setValues] = useState({
    userName: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    errorUsername: "",
    errorPassword: "",
  });
  const [isLoading, setLoading] = useState(false);

  const useStyles = makeStyles((theme) => ({
    paper: {
      maxWidth: 564,
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(5),
      boxShadow: "none",
 
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    loginPage: {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    },
    loginLogo: {
      width: "240px",
      height: "auto",
      margin: "1rem 4rem",
      objectFit: "contain",

      // Mobile & mini ipad < 600px
      [theme.breakpoints.down("xs")]: {
        width: "180px",
      },
    },
    signIntxt: {
      fontSize: "32px",
      fontFamily: "InterMedium",
      fontWeight: "900",
    },
    createAccount: {
      padding: "10px",
      fontSize: "16px",
      fontFamily: "InterMedium",
    },
    createTxt: {
      color: props.page.color_background,
      cursor: "pointer",
      fontFamily: "InterMedium",
    },
    subText: {
      padding: "10px",
      fontSize: "14px",
      fontFamily: "InterMedium",
    },
    forgotTxt: {
      fontSize: "16px",
      padding: "15px",
      fontWeight: "700",
      color: props.page.color_background,
      cursor: "pointer",
    },
  }));
  const classes = useStyles();
  const globalClasses = formStyles(props.page);
  const dispatch = useDispatch();

  // input filed values assigning
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrors({ errorUsername: "", errorPassword: "", errorMessage: "" });
  };

  const handleFormFieldclass = (fieldValue) => {
    let txtFiledValue;
    if (errors[fieldValue]) txtFiledValue = globalClasses.errortextfield;
    else txtFiledValue = globalClasses.textField;
    return txtFiledValue;
  };

  const handleSignIn = async () => {
    setLoading(true);
    const userErr = validateUserName(values.userName);
    const passwordErr = validatePassword(values.password, true);

    // Clear Errors
    setErrors({ ...errors, errorUsername: "", errorPassword: "" });

    if (userErr == "" && passwordErr == "") {
      const loginRes = login(
        values.userName,
        values.password,
        props.page.dpn_instance.dpn_instance
      );

      loginRes
        .then((res) => {
          setLoading(false);
          const { token } = res.data;
          if (token) {
            
            dispatch(accountActions.setUserLoginStatus());
            localStorage.setItem("token", token);
            getUserInfo(token, props.page.dpn_instance.dpn_instance).then(
              (user) => {
                dispatch(accountActions.setUserDetails(user))
                localStorage.setItem("userData", JSON.stringify(user));
              }
            );
            const params = new URLSearchParams(location.search);
            const focusParam = params.get('focus');            
            const homeUrl = getFullPath('/home');
            const redirectUrl = focusParam ? `${homeUrl}?focus=${focusParam}` : homeUrl;
            
            navigate(redirectUrl);
          }
        })
        .catch(() => {
          setLoading(false);
          setErrors({
            ...errors,
            errorMessage: "Incorrect username or password",
          });
        });
    } else {
      setErrors({
        ...errors,
        errorUsername: userErr,
        errorPassword: passwordErr,
      });
      setLoading(false);
    }
  };

  if (!loginConfig) {
    return <>Loading...</>;
  }

  return (
    <div className={classes.loginPage}>
      <Grid item xs={12} zeroMinWidth style={{textAlign:logo_Alignment}}>
        <a onClick={() => navigate(`/${props.page.brandsite_name}`)}>
          <img
            tabIndex={0}
            className={classes.loginLogo}
            alt="logo"
            src={loginConfig.logo}
          />
        </a>
      </Grid>

      <Paper className={classes.paper}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item xs={12} zeroMinWidth style={{ textAlign: "center" }}>
            <Typography
              tabIndex={0}
              className={classes.signIntxt}
              noWrap
              data-testid="signIn"
            >
              {loginConfig.page_title}
            </Typography>

            <Typography
              tabIndex={0}
              className={classes.createAccount}
              noWrap
              data-testid="donthaveAccount"
            >
              Don't have an account?
              <span
                className={classes.createTxt}
                onClick={() => navigate(`/${props.page.brandsite_name}/signup`)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`/${props.page.brandsite_name}/signup`);
                  }
                }}
                data-testid="createAccount"
              >
                {" "}
                Sign Up
              </span>
            </Typography>

            {errors.errorMessage !== "" &&
              errors.errorMessage !== undefined && (
                <div className={globalClasses.alertBoxerror}>
                  Incorrect username or password
                </div>
              )}

            <Grid item xs={12}>
              <FormControl
                className={globalClasses.formInputControl}
                variant="outlined"
              >
                <label htmlFor="Email">
                  <FormHelperText
                    className={globalClasses.inputLabel}
                    id="outlined-weight-helper-text"
                  >
                    Email{" "}
                    <span className={globalClasses.mandatoryField}>*</span>
                  </FormHelperText>
                </label>

                <TextField
                  id="Email"
                  variant="outlined"
                  error={errors.errorUsername != ""}
                  value={values.userName}
                  onChange={handleChange("userName")}
                  className={handleFormFieldclass("errorUsername")}
                />
              </FormControl>
              {errors.errorUsername !== "" && (
                <FormHelperText
                  className={globalClasses.errorMessage}
                  id="outlined-weight-helper-text"
                >
                  {`Please enter your user name`}
                </FormHelperText>
              )}

              <FormControl
                className={globalClasses.formInputControl}
                variant="outlined"
              >
                <label htmlFor="outlined-basic-password">
                  <FormHelperText
                    className={globalClasses.inputLabel}
                    id="outlined-weight-helper-text"
                  >
                    Password{" "}
                    <span className={globalClasses.mandatoryField}>*</span>
                  </FormHelperText>
                </label>
                <TextField
                  id="outlined-basic-password"
                  variant="outlined"
                  error={errors.errorPassword != ""}
                  value={values.password}
                  type="password"
                  onChange={handleChange("password")}
                  className={handleFormFieldclass("errorPassword")}
                />
              </FormControl>
              {errors.errorPassword !== "" && (
                <FormHelperText
                  className={globalClasses.errorMessage}
                  id="outlined-weight-helper-text"
                >
                  Please enter your password
                </FormHelperText>
              )}

              <Button
                variant="contained"
                onClick={handleSignIn}
                disabled={isLoading}
                className={globalClasses.submitBtn}
                style={{color:text_color, backgroundColor:primary_color}}
              >
                {/* {!isLoading ? ( */}
                <div data-testid="signBtn" className="signBtn">
                  {loginConfig.submit_btn_text}
                </div>
                {/* // ) : (
                //   <div className="loader" />
                // )} */}
              </Button>
            </Grid>

            <Typography
              tabIndex={0}
              className={classes.forgotTxt}
              noWrap
              onClick={() => navigate(`/${props.page.brandsite_name}/forgot`)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  navigate(`/${props.page.brandsite_name}/forgot`);
                }
              }}
            >
              Forgot Password?
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default LoginWrapper;
