import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { makeStyles } from "@material-ui/core";

function LoyaltyProgress(props) {
  const { offerInfo, color } = props;
  const { targetBalance, rewardGroups, pointsDisplayUnit } = offerInfo;
  const { targetAchieved } =
    rewardGroups.length > 0 && rewardGroups[0].rewards.length > 0
      ? rewardGroups[0].rewards[0]
      : 0;
  const { targetThreshold } =
    rewardGroups.length > 0 && rewardGroups[0].rewards.length > 0
      ? rewardGroups[0]
      : 9999;
  const { clipLimitPerShopper } =
    rewardGroups.length > 0 && rewardGroups[0].rewards.length > 0
      ? rewardGroups[0].rewards[0].offer
      : 0;
  let completed = targetBalance % targetThreshold;
  const maxCompleted = targetThreshold;
  const reset =
    rewardGroups.length > 0 && rewardGroups[0].rewards.length > 0
      ? props.offerInfo.rewardGroups[0].rewards[0].targetAchieved !==
        props.offerInfo.rewardGroups[0].rewards[0].offer.clipLimitPerShopper
      : true;
  if (targetThreshold === targetBalance && reset) {
    completed = 0;
  }
  if (clipLimitPerShopper === targetAchieved) {
    completed = maxCompleted;
  }

  // setting value of display points in the parent
  props.setValue(maxCompleted);

  const useStyles = makeStyles((theme) => ({
    progressBarLabel: {
      fontSize: "14px",
      fontWeight: "600",
      // lineHeight: "140%",
      textAlign: "left",
      color: "#25272C",
      padding: "4px 0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "12px",

      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "12px",
        // lineHeight: "120%",
        padding: "16px 8px",  
      },
    },
  }));
  const classes = useStyles();
  const displayUnit = pointsDisplayUnit;

  return (
    <>
      <div style={{ margin: "16px 10px 0 10px", width: '90%' }}>
        <ProgressBar
          type="button"
          customLabel=" "
          completed={completed}
          maxCompleted={maxCompleted}
          height={8}
          bgColor={color}
        />
      </div>
      <div className={classes.progressBarLabel}>
        {/* display complete text */}
        {clipLimitPerShopper === targetAchieved ? (
          <span>Completed</span>
        ) : (
          <>
            <span>
              {(displayUnit.includes("cents") ||
                displayUnit.includes("dollar")) &&
                "Spend"}
            </span>
            <span>{displayUnit.includes("point") && "Earn"} </span>
            <span>
              {(displayUnit.includes("item") ||
                displayUnit.includes("purchase")) &&
                "Purchase"}
            </span>
            &nbsp;
            {(displayUnit.includes("cents") ||
              displayUnit.includes("dollar")) &&
              "$"}
            {displayUnit.includes("cents") ? ((maxCompleted - completed) / 100) : (maxCompleted - completed)} more{" "}
            {displayUnit.includes("items") && "Items"}
            {displayUnit.includes("point") && "Points"}
          </>
        )}
      </div>
    </>
  );
}

export default LoyaltyProgress;
