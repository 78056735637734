import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  FormControl,
  FormHelperText,
  TextField,
  InputAdornment,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CLEAR_USER } from "../../redux/reducers/user";
import { validatePassword } from "../../shared/util/UserValidations";
import { changePassword } from "../../service/account_service";
import HeaderNavigation from "../headerNavigation";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";

function ChangePassword(props) {
  const { onClose, isMobile } = props;
  const dpnInstance = props?.retailer?.dpn_instance?.dpn_instance;
  const signupConfig = props.retailer.components.find(
    (component) => component.block_type === "SignupBlock"
  );
  const header = props.retailer.components.find(
    (component) => component.block_type === "HeaderNavigationBlock"
  );
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      overflow: "hidden",
      padding: theme.spacing(0, 1),
    },
    content: {
      backgroundColor: theme.palette.background.paper,
      margin: "0 10px",
      outline: "none",
      textAlign: "center",
    },
    paper: {
      maxWidth: 630,
      boxShadow: "none",
    },
    paperMobile: {
      maxWidth: 560,
      boxShadow: "none",
    },
    margin: {
      display: "flex",
      margin: theme.spacing(1),
      marginLeft: "20px",
    },
    marginMobile: {
      display: "flex",
      margin: theme.spacing(1),
      marginLeft: "12px",
    },
    textField: {
      fontSize: "16px",
      "&>div": {
        fontSize: "16px",
        height: "48px",
      },
      "& input": {
        padding: "14.5px 14px",
      },
    },
    alertBoxMobile: {
      display: "flex",
      alignItems: "center",
      padding: "1.4rem 1.6rem",
      borderRadius: "8px",
      color: " #561104",
      background: "#FBEAE7",
      border: "1px solid #D82B0C",
      fontSize: "16px",
      width: "96%",
      marginLeft: "9px",
      marginTop: "10px",
    },
    alertBoxerror: {
      display: "flex",
      alignItems: "center",
      padding: "1.4rem 1.6rem",
      borderRadius: "8px",
      color: " #561104",
      background: "#FBEAE7",
      border: "1px solid #D82B0C",
      fontSize: "16px",
      width: "94%",
      marginTop: "14px",
      marginLeft: "24px",
    },
    header: {
      display: "flex",
      "& .pageTitle": {
        marginLeft: "20px",
      },
    },
    mobileHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      "& .backImgBtn": {
        marginTop: "10px",
      },

      "& .pageTitle": {
        marginTop: "10px",
        fontSize: "26px",
      },
    },
    submitBtnCtn: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      flexWrap: "wrap",
    },
    submitBtn: {
      color: "white",
      outline: "none",
      padding: "1.2rem 1.6rem",
      fontSize: "16px",
      width: "190px",
      borderRadius: "25px",
      boxShadow: "none",
      fontWeight: "bold",
      lineHeight: 1,
      backgroundColor: `${props?.retailer?.color_background}`,
      textTransform: "capitalize",
      marginTop: "14px",
      marginLeft: "17px",
      "&:hover": {
        boxShadow: "none",
        backgroundColor: `${props?.retailer?.color_background}`,
      },
      "&:focus": {
        boxShadow: "none",
        backgroundColor: `${props?.retailer?.color_background}`,
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },
    },
    submitBtnMobile: {
      color: "white",
      outline: "none",
      padding: "1.2rem 1.6rem",
      fontSize: "16px",
      width: "96%",
      boxShadow: "none",
      lineHeight: 1,
      backgroundColor: `${props?.retailer?.color_background}`,
      textTransform: "capitalize",
      marginLeft: "5px",
      marginTop: "10px",
      "&:hover": {
        boxShadow: "none",
        backgroundColor: `${props?.retailer?.color_background}`,
      },
      "&:focus": {
        boxShadow: "none",
        backgroundColor: `${props?.retailer?.color_background}`,
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },
    },
    loyaltyId: {
      fontSize: "14px",
      color: "#212931",
      fontWeight: "700",
      marginLeft: 0,
    },
    password: {
      fontSize: "14px",
      color: "#212931",
      fontWeight: "700",
      marginLeft: 0,
    },
    mandatoryField: {
      color: "red",
    },
    errorMessage: {
      fontSize: "14px",
      fontWeight: "400",
      marginLeft: "28px",
      color: "#D82B0C",
      fontStyle: "normal",
    },
    errorMobile: {
      fontSize: "14px",
      fontWeight: "400",
      marginLeft: "12px",
      color: "#D82B0C",
      fontStyle: "normal",
    },
  }));

  const [formControls, setFormControls] = useState({
    CurrentPassword: {
      key: "CurrentPassword",
      label: "Current Password",
      value: "",
      errorMsg: "",
    },
    NewPassword: {
      key: "NewPassword",
      label: "New Password",
      value: "",
      errorMsg: "",
    },
    ConfirmNewPassword: {
      key: "ConfirmNewPassword",
      label: "Confirm New Password",
      value: "",
      errorMsg: "",
    },
  });
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const errorIcon = <ErrorOutlineOutlinedIcon style={{ fill: "#ff0000" }} />;
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (prop) => (event) => {
    const fControls = formControls;
    fControls[prop].value = event.target.value;
    setFormControls({ ...fControls });
  };

  const handleChangePassword = () => async () => {
    setLoading(true);
    const fControls = { ...formControls };

    // Clear Erros
    setErrorMsg(null);
    Object.values(fControls).map((fcontrol) => {
      fcontrol.errorMsg = "";
    });
    setFormControls({ ...fControls });

    // NoN empty validation
    let noErros = true;
    for (const fControl of Object.values(fControls)) {
      if (fControl.value === "") {
        fControl.errorMsg = `Please enter your ${fControl.label}`;
        noErros = false;
      }
    }

    // Validate password length
    if (noErros) {
      const passwordErr = validatePassword(fControls.NewPassword.value);
      if (passwordErr !== "") {
        fControls.NewPassword.errorMsg = passwordErr;
        noErros = false;
      }

      if (fControls.NewPassword.value !== fControls.ConfirmNewPassword.value) {
        fControls.ConfirmNewPassword.errorMsg =
          "Password does not match the new password";
        noErros = false;
      }
    }
    setFormControls({ ...fControls });

    if (noErros) {
      const passwordData = {
        current: fControls.CurrentPassword.value,
        updated: fControls.NewPassword.value,
      };

      const res = await changePassword(passwordData, dpnInstance);
      if (res.error) {
        res.message
          ? setErrorMsg(res.message)
          : (fControls.CurrentPassword.errorMsg = res.error);
        setFormControls({ ...fControls });
      } else {
        // On success redirect to login page based on the config
        setTimeout(() => {
          dispatch(CLEAR_USER());
          localStorage.clear();
          navigate(`/${props?.retailer?.brandsite_name}/login`);
        }, 1000);
      }
    }
    setLoading(false);
  };

  return (
    <Paper className={isMobile ? classes.paperMobile : classes.paper}>
      <Box
        style={{
          width: "100%",
          height: isMobile ? "100vh" : "100%",
          background: "#fff",
        }}
      >
        {isMobile && header && <HeaderNavigation page={props.retailer} />}
        <div className={classes.content}>
          <div className={isMobile ? classes.mobileHeader : classes.header}>
            <div className="backImgBtn" data-testid="backButton">
              {isMobile && (
                <button
                  style={{ background: "none", border: "none" }}
                  onClick={() => onClose()}
                >
                  <ArrowBackOutlinedIcon style={{ fill: "#010101" }} />
                </button>
              )}
            </div>
            <Typography
              className="pageTitle"
              align="left"
              variant="h3"
              data-testid="passwordHeader"
              component="h3"
              noWrap
            >
              Password
            </Typography>
            <div></div>
          </div>

          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={12} zeroMinWidth style={{ textAlign: "center" }}>
              {Object.values(formControls).map((formControl, idx) => (
                <Grid item xs={12}>
                  <FormControl
                    className={isMobile ? classes.marginMobile : classes.margin}
                    variant="outlined"
                  >
                    <label htmlFor={`${idx}-account.${formControl.key}`}>
                      <FormHelperText
                        fro="password"
                        className={classes.loyaltyId}
                        id="outlined-weight-helper-text"
                      >
                        {formControl.label}
                        <span className={classes.mandatoryField}> *</span>
                      </FormHelperText>
                    </label>
                    <TextField
                      id={`${idx}-account.${formControl.key}`}
                      type="password"
                      variant="outlined"
                      error={formControl.errorMsg}
                      value={formControl.value}
                      onChange={handleChange(formControl.key)}
                      className={classes.textField}
                      InputProps={
                        formControl.errorMsg
                          ? {
                              endAdornment: (
                                <InputAdornment position="start">
                                  {errorIcon}
                                </InputAdornment>
                              ),
                            }
                          : ""
                      }
                    />
                  </FormControl>
                  {formControl.errorMsg && (
                    <FormHelperText
                      className={
                        isMobile ? classes.errorMobile : classes.errorMessage
                      }
                      id="outlined-weight-helper-text"
                    >
                      {` ${formControl.errorMsg}`}
                    </FormHelperText>
                  )}
                </Grid>
              ))}

              <Grid item xs={12}>
                {errorMsg && (
                  <FormHelperText
                    className={
                      isMobile ? classes.alertBoxMobile : classes.alertBoxerror
                    }
                    id="outlined-weight-helper-text"
                  >
                    {errorMsg}
                  </FormHelperText>
                )}
              </Grid>

              <Grid className={classes.submitBtnCtn}>
                <Button
                  variant="contained"
                  onClick={handleChangePassword()}
                  disabled={isLoading}
                  className={
                    isMobile ? classes.submitBtnMobile : classes.submitBtn
                  }
                >
                  {!isLoading ? (
                    <div data-testid="submitBtn" className="submitBtn">
                      Change Password
                    </div>
                  ) : (
                    <div className="loader-button" />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Box>
    </Paper>
  );
}

export default ChangePassword;
