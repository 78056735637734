import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Grid,
  Modal,
  Slide,
  Box,
  makeStyles,
  createTheme,
  useMediaQuery,
} from "@material-ui/core";
import ProfileBody from "../blocks/Account/Profile";
import LogoutModal from "../blocks/Account/LogoutModel";
import ChangePassword from "../blocks/Account/ChangePassword";
import HeaderNavigation from "../blocks/headerNavigation";
import FooterV2 from "../footerV2/footer";
import { CLEAR_USER } from "../redux/reducers/user";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import { TabPanel, MatTabs, MatTab, a11yProps } from "../utils/matTab";

function AccountPage({ page }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      [theme.breakpoints.up("md")]: {
        padding: "0 17%",
      },
    },
    container: {
      margin: 0,
      maxWidth: "100%",
      [theme.breakpoints.up("sm")]: {
        margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
        maxWidth: "95%",
      },
      // Mobile & mini ipad < 600px
      [theme.breakpoints.down("xs")]: {
        padding: "10px",
        height: "100vh",

        "& .tabPanelSection": {
          display: "none",
        },
      },
      [theme.breakpoints.up("md")]: {
        margin: `${theme.spacing(0)}px ${theme.spacing(1)}px`,
        padding: `0 ${theme.spacing(0)}px`,
        maxWidth: "97%",
      },
      [theme.breakpoints.up("lg")]: {
        margin: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(
          3
        )}px
                  ${theme.spacing(2)}px`,
        maxWidth: "96%",
      },
      [theme.breakpoints.up("1800")]: {
        margin: `0`,
        maxWidth: "98%",
      },
      [theme.breakpoints.up("xl")]: {
        margin: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(
          3
        )}px
                  ${theme.spacing(5)}px`,
        maxWidth: "95%",
      },
    },
    headSection: {
      marginBottom: "24px",

      "&.title $element": {
        fontSize: "20px",
      },
      "& .sub-title": {
        fontSize: "16px",
        opacity: 0.6,
      },
      "& $title": {
        marginTop: 30,
      },

      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        "& .headSection": {
          display: "none",
        },
      },
    },
    titleCtn: {
      margin: "10px 0",
    },
    muiTab: {
      "& .MuiTab-root": {
        color: "#5A6D81",
      },
      "& .Mui-selected": {
        color: "#212931",
      },
      "& .MuiSvgIcon-root": {
        marginRight: "10px",
        width: "24px",
      },
      "& .MuiTab-wrapper": {
        flexDirection: "row",
        justifyContent: "flex-start",
        fontWeight: 400,
        letterSpacing: "normal",
        whiteSpace: "nowrap",
      },
    },
  }));
  const pageConfig = page.find((page) => page.slug === "account");
  const retailerConfig = useSelector((state) => state.config.retailerConfig);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [openChangePwdModal, setOpenChangePwdModal] = useState(false);

  const header = retailerConfig.components.find(
    (component) => component.block_type === "HeaderNavigationBlock"
  );
  const footer = retailerConfig.components.find(
    (component) => component.block_type === "FooterBlock"
  );

  const tabList = [
    {
      label: "MyAccount.profile",
      value: "Profile",
      icon: <AccountCircleOutlinedIcon />,
    },
    {
      label: "MyAccount.password",
      value: "Password",
      icon: <LockOutlinedIcon />,
    },
    {
      label: "MyAccount.signout",
      value: "Sign Out",
      icon: <ExitToAppOutlinedIcon />,
    },
  ];

  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  const onTabChangeHandler = (tab, idx) => {
    if (isMobile) {
      switch (tab.value) {
        case "Profile":
          setTabValue(idx);
          setOpenProfileModal(true);
          break;
        case "Password":
          setTabValue(idx);
          setOpenChangePwdModal(true);
          break;
        case "Sign Out":
          setOpenLogoutModal(true);
          break;
      }
    } else {
      tab.value === "Sign Out" ? setOpenLogoutModal(true) : setTabValue(idx);
    }
  };

  const onLogout = () => {
    dispatch(CLEAR_USER());
    localStorage.clear();
    navigate(`/${page.brandsite_name}/login`);
  };
  return (
    <>
      {header && <HeaderNavigation page={retailerConfig} />}
      <Container
        classes={{
          maxWidthLg: classes.container,
          maxWidthMd: classes.container,
        }}
      >
        <Grid className={classes.root} container item xs={12}>
          <Grid item xs={isMobile ? 12 : 4}>
            <div className={classes.titleCtn}>
              <Typography variant="h5">My Account</Typography>
            </div>

            <MatTabs
              className={classes.muiTab}
              value={tabValue}
              orientation="vertical"
            >
              {tabList.map((tab, idx) => (
                <MatTab
                  label={tab.value}
                  onClick={() => onTabChangeHandler(tab, idx)}
                  retailer={retailerConfig}
                  icon={tab.icon}
                  iconPosition="start"
                  tabIndex="0"
                  {...a11yProps(idx + 1)}
                />
              ))}
            </MatTabs>
          </Grid>

          <Grid className="tabPanelSection" item xs={8}>
            <TabPanel value={tabValue} index={0}>
              <div className="profile-v3-ctn">
                <ProfileBody retailer={retailerConfig} isMobile={isMobile} />
              </div>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <div className="profile-v3-ctn">
                <ChangePassword retailer={retailerConfig} isMobile={isMobile} />
              </div>
            </TabPanel>
          </Grid>
        </Grid>

        {openProfileModal && (
          <Modal
            open={openProfileModal}
            className="v2-modal"
            onClose={() => setOpenProfileModal(false)}
            aria-labelledby="filters-modal"
          >
            <Slide direction="left" in={openProfileModal} timeout={1000}>
              <Box style={{ height: "100%", backgroundColor: "#fff" }}>
                <ProfileBody
                  retailer={retailerConfig}
                  isMobile={isMobile}
                  onClose={() => setOpenProfileModal()}
                />
              </Box>
            </Slide>
          </Modal>
        )}

        {openChangePwdModal && (
          <Modal
            open={openChangePwdModal}
            className="v2-modal"
            onClose={() => setOpenChangePwdModal(false)}
            aria-labelledby="filters-modal"
          >
            <Slide direction="left" in={openChangePwdModal} timeout={1000}>
              <Box style={{ height: "100vh", backgroundColor: "#fff" }}>
                <ChangePassword
                  retailer={retailerConfig}
                  isMobile={isMobile}
                  onClose={() => setOpenChangePwdModal(false)}
                />
              </Box>
            </Slide>
          </Modal>
        )}

        {openLogoutModal && (
          <LogoutModal
            open={openLogoutModal}
            mainColor={retailerConfig?.color_background}
            mainColorHover={retailerConfig?.color_background}
            logout={() => onLogout()}
            closeModal={() => setOpenLogoutModal(false)}
          />
        )}
      </Container>

      {footer && <FooterV2 page={retailerConfig} />}
    </>
  );
}

export default AccountPage;
