import { combineReducers } from "redux";
import offer from "./offer";
import user from "./user";
import config from "./config";
import loyalityPrograms from './loyalityPrograms';

export default combineReducers({
  offer,
  user,
  config,
  loyalityPrograms
});
